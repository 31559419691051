import { UserEntity } from "app/infra/user";

import { ResourceEntity } from "app/infra/resource";
import { StageEntity } from "../stage";
import { TrackEntity } from "../track";

export enum ReactTypes {
  thumbs_up = "thumbs_up",
  fire = "fire",
  love = "love",
  explosion = "explosion",
}

export interface TalkEntity {
  id: string;
  stage_id: string;
  // stage?: StageEntity<string>;
  title: string;
  description: string;
  start_time: string;
  length: number;
  speakers?: UserEntity[];
  cover_url?: string;
  is_finished: boolean;
  track_id: string;
  offset: number;
  video_url: string;
  video_url_2: string;
  video_url_3: string;
  video_url_4: string;
  tags: string[];
  resources?: ResourceEntity[];
  has_discussion: boolean;
  show_controls: boolean;
  chat_channel_name: string;
  chat_channel_id: string;
  hasNetworkingMixer: boolean;
}

export interface TalkEntityExtended extends TalkEntity {
  stage: StageEntity<any>;
  track: TrackEntity;
}

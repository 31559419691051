import React, { useMemo, useState } from "react";

import { Checkbox, Dropdown, Menu } from "antd";
import { ChevronDown, ChevronUp } from "react-iconly";
import Icons from "components/Icons";

export interface StringBooleanMap {
  [key: string]: boolean;
}

interface Item {
  id: string | number;
  name: string;
  children?: Item[] | null;
}

interface FilterProps {
  placeholder: string;
  items: Item[];
  selectedItems: StringBooleanMap;
  setSelectedItems: (obj: StringBooleanMap) => void;
}

const CheckboxFilter = (props: FilterProps) => {
  const toggleCheckItem = (id: string) => {
    props.setSelectedItems({
      ...props.selectedItems,
      [id]: props.selectedItems[id] ? !props.selectedItems[id] : true,
    });
  };

  const [itemsVisible, setItemsVisible] = useState(false);

  const numberOfItems = useMemo(() => {
    return Object.values(props.selectedItems).filter(Boolean).length;
  }, [props.selectedItems]);

  const selectedItemsArray = useMemo(() => {
    return Object.keys(props.selectedItems).filter((key) => props.selectedItems[key]);
  }, [props.selectedItems]);

  const selectedItemsName = useMemo(() => {
    return props.items.filter((item) => item.id.toString() === selectedItemsArray[0]);
  }, [props.items, props.selectedItems]);

  const getHeaderItems = () => {
    return (
      <div style={{ display: "flex", paddingLeft: "1rem" }}>
        {numberOfItems > 0 && (
          <Icons.Cancel
            style={{ marginRight: 5, position: "initial", flexShrink: 0 }}
            onClick={() => props.setSelectedItems({})}
          />
        )}

        <span
          style={{
            marginRight: "auto",
            color: "#A3A7B2",
            fontSize: "14px",
            fontWeight: 400,
            overflow: "hidden",
          }}
        >
          {selectedItemsArray.length === 0 && (
            props.placeholder
          )}
          {selectedItemsArray.length === 1 && (
            selectedItemsName.slice(0, 1).map((item) => (
              <span
                key={`item-name-${item.id}`}
                style={{
                  backgroundColor: "#f1f1f1",
                  color: "#686868",
                  padding: "0 15px",
                  marginRight: 5,
                  borderRadius: 20,
                  whiteSpace: "nowrap",
                }}
              >
                {item.name}
              </span>
            ))
          )}
          {selectedItemsArray.length > 1 && (
            <span>
              {selectedItemsArray.length} items
            </span>
          )}
        </span>

        {itemsVisible ? (
          <ChevronUp
            set="light"
            primaryColor="#4B4C53"
            style={{ flexShrink: 0 }}
          />
        ) : (
          <ChevronDown
            set="light"
            primaryColor="#4B4C53"
            style={{ flexShrink: 0 }}
          />
        )}
      </div>
    );
  };

  const dropdownItems = (
    <Menu className="column-checkbox">
      <div
        style={{
          backgroundColor: "#d9d9d9",
          width: "100%",
          height: "1px",
          marginBottom: "1rem",
        }}
      />

      {props.items.map((item: Item) => (
        <>
          {item.children && (
            <div>{item.name}</div>
          )}

          {item.children ? (
            item.children.map((child: Item) => (
              <Menu.Item key={`track-menu-item-${child.id}`}>
                <Checkbox
                  key={child.id}
                  checked={props.selectedItems[child.id]}
                  onChange={() => {
                    toggleCheckItem(child.id.toString());
                  }}
                >
                  {child.name}
                </Checkbox>
              </Menu.Item>
            ))
          ) : (
            <Menu.Item key={`track-menu-item-${item.id}`}>
              <Checkbox
                key={item.id}
                checked={props.selectedItems[item.id]}
                onChange={() => {
                  toggleCheckItem(item.id.toString());
                }}
              >
                {item.name}
              </Checkbox>
            </Menu.Item>
          )}
        </>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown
        className="filters-dropdown"
        overlayClassName="filters-dropdown-content"
        visible={itemsVisible}
        overlay={dropdownItems}
        trigger={["click"]}
        onVisibleChange={(visible) => setItemsVisible(visible)}
      >
        {getHeaderItems()}
      </Dropdown>
    </>
  );
};

export default CheckboxFilter;

import { CommonServiceDeps } from "app/common";
import { ReplayTrackEntity } from "./replayTrack.entity";
import { replayTrackStore } from "./replayTrack.store";
import { AppDispatch } from "services";

export interface CreateTrackDTO {
  name: string;
  color: string;
  sponsor: string;
}

export interface UpdateTrackDTO extends CreateTrackDTO {}

// Nothing to transform
export const addReplayTracks = (dispatch: AppDispatch) => (tracks: ReplayTrackEntity[]) => {
  dispatch(
    replayTrackStore.actions.updateResources({
      resources: tracks,
    })
  );
};

export const replayTrackService = ({ apiService, dispatch }: CommonServiceDeps) => ({
  create: (data: CreateTrackDTO) =>
    apiService.post<ReplayTrackEntity>("replay/tracks", data).then((response) => {
      addReplayTracks(dispatch)([response]);
      return response;
    }),
  delete: (id: string) =>
    apiService.delete(`replay/tracks/${id}`).then(() => {
      dispatch(replayTrackStore.actions.deleteResource({ id }));
    }),
  getAll: () =>
    apiService.get<ReplayTrackEntity[]>("replay/tracks").then((response) => {
      addReplayTracks(dispatch)(response);
      return response;
    }),
  // TODO: Fix this
  update: (data: UpdateTrackDTO, id: string) =>
    apiService.patch(`replay/tracks/${id}`, {
      ...data,
      id: undefined,
      sponsor_image_url: undefined,
      sponsorurl: undefined,
    }),
});

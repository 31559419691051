import React, { useMemo, useContext } from "react";

import { connect } from "react-redux";
import { RootState } from "services";

import { useSessionState } from "hooks/useSessionState";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { Button, Col, Input, Row } from "antd";

import { ReplayTrackEntity } from "app/infra/replayTrack";
import { ReplayTalkEntityExtended } from "app/infra/replayTalk";

import { ReplayStageEntity, replayStageService } from "app/infra/replayStage";
import { DiContext, useAsync } from "app/common/utils";

import { Search } from "react-iconly";
import { Loading } from "components/Loading/Loading";
import { TalkItem } from "./talk.item";
import Icons from "components/Icons";
import CheckboxFilter from "components/CheckboxFilter/CheckboxFilter";

interface StringBooleanMap {
  [key: string]: boolean;
}

interface AllTalksPageProps {
  tracks: ReplayTrackEntity[];
  stages: ReplayStageEntity<string>[];
  talks: ReplayTalkEntityExtended[];
}

const mapStateToProps = (state: RootState): AllTalksPageProps => ({
  tracks: Object.values(state.replayTrackStore.byId),
  stages: Object.values(state.replayStageStore.byId),
  talks: Object.values(state.replayTalkStore.byId).map((talk) => ({
    ...talk,
    stage: state.replayStageStore.byId[talk.stage_id],
    track: state.replayTrackStore.byId[talk.track_id],
  })),
});

export const ReplayPage = connect(mapStateToProps)(
  (props: AllTalksPageProps) => {
    const { apiService, dispatch } = useContext(DiContext);
    const stagesSrv = replayStageService({ apiService, dispatch });

    const { execute, pending, error } = useAsync(stagesSrv.getAgenda, false);

    useOnError(error);

    useOnMount(() => {
      execute();
    });

    const [filter, setFilter] = useSessionState<string>("replays:search", "");
    const [selectedTracks, setSelectedTracks] = useSessionState<StringBooleanMap>("replays:tracks", {});
    const [selectedStages, setSelectedStages] = useSessionState<StringBooleanMap>("replays:stages", {});

    const selectedTracksArray = useMemo(() => {
      return Object.keys(selectedTracks).filter((key) => selectedTracks[key]);
    }, [selectedTracks]);

    const selectedStagesArray = useMemo(() => {
      return Object.keys(selectedStages).filter((key) => selectedStages[key]);
    }, [selectedStages]);

    const showClearAllFilters = useMemo(() => {
      return (
        filter.length > 0
        || selectedTracksArray.length > 0
        || selectedStagesArray.length > 0
      );
    }, [filter, selectedTracksArray, selectedStagesArray]);

    const clearFilters = (): void => {
      setFilter("");
      setSelectedTracks({});
      setSelectedStages({});
    };

    const tracks = useMemo(() => {
      return props.tracks
        .filter((track) => track.name !== "Break")
        .sort((a, b) => (a.name < b.name ? 1 : -1));
    }, [props.tracks]);

    const stages = useMemo(() => {
      return props.stages
        .filter((stage) => stage.name !== "Break")
        .sort((a, b) => (a.name < b.name ? 1 : -1));
    }, [props.stages]);

    const talks = useMemo(() => {
      let entries = props.talks;

      if (selectedTracksArray.length > 0) {
        entries = entries.filter((entry) =>
          selectedTracksArray.includes(entry.track_id.toString()));
      }

      if (selectedStagesArray.length > 0) {
        entries = entries.filter((entry) =>
          selectedStagesArray.includes(entry.stage_id.toString()));
      }

      if (filter !== "") {
        const strings = filter.toLowerCase().split(" ");
        entries = entries.filter((entry) => {
          const title = entry.title.toLowerCase();
          const description = entry.description.toLowerCase();

          return strings.reduce((acc: boolean, value: string) => {
            if (acc) return acc;
            return (
              value !== "" &&
              (title.search(value) !== -1 || description.search(value) !== -1)
            );
          }, false);
        });
      }

      return entries;
    }, [props.talks, selectedTracksArray, selectedStagesArray, filter]);

    return (
      <div className="wrapper">
        <div className="agenda-page">
          <div className="checkbox-filters">
            <div className="search-input">
              <Input
                className="adw-white-input"
                size="large"
                placeholder="Search"
                prefix={<Search set="light" primaryColor="#A3A7B2" size={20} />}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
              {filter && <Icons.Cancel onClick={() => setFilter("")} />}
            </div>

            <div className="filter-right">
              {showClearAllFilters && (
                <Button
                  type="link"
                  onClick={clearFilters}
                  className="clear-filters-btn"
                >
                  Clear Filters
                </Button>
              )}

              <CheckboxFilter
                items={tracks.map((element) => {
                  return {
                    id: element.id,
                    name: element.name,
                  };
                })}
                placeholder="Tracks"
                selectedItems={selectedTracks}
                setSelectedItems={setSelectedTracks}
              />

              <CheckboxFilter
                items={stages.map(element => {
                  return {
                    id: element.id,
                    name: element.name,
                  };
                })}
                placeholder="Stages"
                selectedItems={selectedStages}
                setSelectedItems={setSelectedStages}
              />

            </div>
          </div>
          <div
            className="main-content"
            style={{
              padding: 0,
            }}
          >
            <Row className="replays-ant-row" gutter={[24, 24]}>
              {talks.map((talk) => (
                <Col key={`replay-ant-col-${talk.id}`} sm={24} md={12} lg={8} xl={6}>
                  <div className="talk-item-container">
                    <TalkItem talk={talk} />
                  </div>
                </Col>
              ))}
            </Row>

            {talks.length === 0 && (
              <>
                {pending ? (
                  <Loading
                    size={40}
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        color: "#181a55",
                        fontSize: 17,
                      }}
                    >
                      No replay talks with that search criteria!
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
);

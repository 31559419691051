import { createSlice } from "@reduxjs/toolkit";
import { deleteResource, InitialState, updateResources } from "app/common";
import { StageEntity } from "./stage.entity";

const initialState = {
  keyIds: {},
  byId: {},
} as StageInitialState;

interface StageInitialState extends InitialState<StageEntity<string>> {}

export const stageStore = createSlice({
  name: "stageStore",
  initialState,
  reducers: {
    reset: () => initialState,
    deleteResource,
    updateResources,
  },
});

import { CommonServiceDeps } from "app/common";
import { TrackEntity } from "./track.entity";
import { trackStore } from "./track.store";

type Track = Pick<TrackEntity, "name" | "color" | "light"> & { sponsor: string };

export const trackService = (props: CommonServiceDeps) => {
  return {
    getAll: () => {
      return props.apiService
        .get("tracks")
        .then((response: TrackEntity[]) => {
          props.dispatch(trackStore.actions.addResources({ resources: response }));
          return response;
        });
    },
    create: (data: Track) => {
      return props.apiService
        .post<TrackEntity>("tracks", data)
        .then((response) => {
          props.dispatch(trackStore.actions.addResources({ resources: [response] }));
          return response;
        });
    },
    update: (data: Track, id: string) => {
      return props.apiService
        .patch(`tracks/${id}`, data)
        .then((response: TrackEntity) => {
          props.dispatch(trackStore.actions.updateResources({ resources: [response] }));
          return response;
        });
    },
    delete: (id: string) => {
      return props.apiService
        .delete(`tracks/${id}`)
        .then((response) => {
          props.dispatch(trackStore.actions.deleteResource({ id }));
          return response;
        });
    },
  };
};

import React from "react";
import { AppModes, mode } from "app/infra/app";

import moment from "moment-timezone";

import { StageList } from "components/Stage/StageList";
import { AllTalksPage } from "./allTalks.page";
import { InfoCircle } from "react-iconly";

export const StagePage = () => {
  return (
    <>
      {mode === AppModes.live ? (
        <div className="stage-page">
          <div className="stage-page-timezone-info">
            <InfoCircle set="bold" />
            Times shown are in your local device's time. ({moment.tz.guess()})
          </div>
          <div className="wrapper">
            <StageList />
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <AllTalksPage />
        </div>
      )}
    </>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import { CompanyEntity } from "app/infra/company";
import { COMPANY } from "config/constants";

interface CompanyCardProps {
  company: CompanyEntity;
}

export const Basic = ({ company }: CompanyCardProps) => {
  const subtitle = [company.company_type, company.country]
    .filter(Boolean)
    .join(" | ");

  return (
    <div className="company-basic">
      <div className="header">
        <p className="title">
          <Link to={`/app/booth/${company.id}`}>{company.name}</Link>
        </p>
        <p className="subtitle">{subtitle}</p>
      </div>

      <div className="view-btn">
        <Link to={`/app/booth/${company.id}`}>{`View ${COMPANY.meta.name}`}</Link>
      </div>
    </div>
  );
};

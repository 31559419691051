import React, { useState, useMemo, CSSProperties } from "react";
import { CompanyEntity } from "app/infra/company";
import { Link } from "react-router-dom";
import { Play, Work } from "react-iconly";
import { Tag, Avatar, Popover } from "antd";

import { Loading } from "components/Loading/Loading";

import Icons from "components/Icons";

import companyHeaderBackground from "assets/images/booths/marketplace-company-header-icon.svg";

import { isDarkColor } from "app/common/utils/isDarkColor";
import { COMPANY } from "config/constants/company";
import { CompanyVideo } from "../CompanyVideo";

interface CompanyPremiumCardProps {
  company: CompanyEntity;
}

const descriptionLimit = 100;

export const Super = ({ company }: CompanyPremiumCardProps) => {
  const description = useMemo(
    () =>
      (company.description.length > descriptionLimit
        && `${company.description.substring(0, descriptionLimit)}...`)
        || company.description,
    [company],
  );

  const [flipped, setFlipped] = useState(false);

  let mixBlendMode: CSSProperties["mixBlendMode"] = "normal";
  if (company.main_color) {
    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      company.main_color,
    );
    const maxColor = 240;
    if (
      rgb
      && ((parseInt(rgb[1], 16) > maxColor && parseInt(rgb[2], 16) > maxColor)
      || (parseInt(rgb[1], 16) > maxColor && parseInt(rgb[3], 16) > maxColor)
      || (parseInt(rgb[2], 16) > maxColor && parseInt(rgb[3], 16) > maxColor))
    ) {
      mixBlendMode = "difference";
    }
  }

  return (
    <div className={`company-super ${isDarkColor(company?.main_color || "") ? "" : "inverted"}`}>
      <div className={`company-top-card ${flipped ? "flipped" : ""}`}>
        <div
          className="company-top-front"
          style={{
            backgroundImage: `linear-gradient(#fff -450%, ${company.main_color})`,
            backgroundColor: company.main_color,
          }}
        >
          <div className="company-header-pattern">
            <img src={companyHeaderBackground} />
          </div>
          <div className="company-image-header">
            <Link to={`/app/booth/${company.id}`}>
              {/* <img src={company.logo_url || placeholderImage} /> */}
              <Avatar
                src={company?.logo_url}
                style={company?.logo_url ? {} : { backgroundColor: "#000000" }}
                size={100}
              >
                <Work set="bold" primaryColor="#FFFFFF" size={60} style={{ verticalAlign: "middle" }} />
              </Avatar>
            </Link>
          </div>

          <div className="company-content">
            <p className="content-title">
              <Link to={`/app/booth/${company.id}`}>{company.name}</Link>
            </p>
            {company.priority ? (
              <p className="content-subtitle">Sponsor</p>
            ) : ""}
            {company.company_type && (
              <p className="content-subtitle">{company.company_type}</p>
            )}
            <p className="description">{description}</p>
          </div>

          {company.promo_video && (
            <div
              className="company-video"
              style={{ mixBlendMode }}
              onClick={() => setFlipped(true)}
            >
              <span className="company-url play-icon">
                <Play set="bold" size="large" />
                {/* <p style={{ color: company.main_color }} /> */}
              </span>
              <span className="video-text">Play Intro Video</span>
            </div>
          )}

          <div className="company-supports">
            {company?.focus && (
              <ul>
                {company.focus.map((interest) => (
                  <li key={`company-support-interests-${interest}`}>
                    <span className="check-icn">
                      <Icons.Check />
                    </span>
                    <span className="check-text">{interest}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {(company?.traffic && (
            <div className="company-popover">
              <span className="text-box">
                We are looking for{" "}
                <Popover
                  className="interests-popover"
                  content={(
                    <div className="interests-popup">
                      {company.traffic.map((interest) => (
                        <Tag
                          key={`super-interests-popup-${interest}`}
                          className={`tag ${interest}`}
                        >
                          {interest}
                        </Tag>
                      ))}
                    </div>
                  )}
                >
                  <span className="i-icon">i</span>
                </Popover>
              </span>
            </div>
          ))
            || ""}

          <div className="chat-now" style={{ mixBlendMode }}>
            <Link to={`/app/booth/${company.id}`}>{`View ${COMPANY.meta.name}`}</Link>
          </div>
        </div>

        <div
          className="company-top-back"
          style={{
            backgroundImage: `linear-gradient(#fff -450%, ${company.main_color})`,
            backgroundColor: company.main_color,
          }}
        >
          <div className="company-image-header">
            <Link to={`/app/booth/${company.id}`}>
              <Avatar
                size={110}
                alt={company?.name}
                src={company?.logo_url}
                icon={null}
              />
            </Link>
          </div>

          <div className="company-content" style={{ marginBottom: "40px" }}>
            <p className="content-title">
              <Link to={`/app/booth/${company.id}`}>{company.name}</Link>
            </p>
            {company.priority && (
              <p className="content-subtitle">Sponsor</p>
            )}
            {company.company_type && (
              <p className="content-subtitle">{company.company_type}</p>
            )}
          </div>

          {company.promo_video ? (
            <>
              {flipped && (
                <CompanyVideo vimeoURL={company.promo_video} isPlaying={flipped} />
              )}

              <div
                className={`company-video ${isDarkColor(company?.main_color || "") ? "" : "inverted"}`}
                style={{ marginTop: "20px" }}
                onClick={() => setFlipped(false)}
              >
                <span className="company-url">
                  <p style={{ color: company.main_color }}>
                    Finished watching?
                  </p>
                </span>
              </div>
            </>
          ) : (
            <Loading size={24} />
          )}

          <div className="chat-now" style={{ mixBlendMode }}>
            <Link to={`/app/booth/${company.id}`}>{`View ${COMPANY.meta.name}`}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

import { CommonServiceDeps } from "app/common";
import { AppDispatch } from "services";
import { TalkEntity, TalkEntityExtended } from "./talk.entity";
import { talkStore } from "./talk.store";
import { addStages, StageEntity } from "../stage";
import { TrackEntity, trackStore } from "../track";

export interface CreateCoverDTO {
  id: string;
  file: string | Blob;
}

export type Talk = Pick<TalkEntityExtended, 
  "title" 
  | "description" 
  | "start_time" 
  | "length" 
  | "speakers" 
  | "track" 
  | "has_discussion" 
  | "hasNetworkingMixer" 
  >;

interface TalkEntityResponse extends TalkEntity {
  stage?: StageEntity<any>;
  track?: TrackEntity;
}

const talksWithEntityToIds = (talks: TalkEntityResponse[]) =>
  talks.map((talk) => ({
    ...talk,
    ...((talk.stage && { stage_id: talk.stage?.id }) || {}),
    ...((talk.track && { track_id: talk.track?.id }) || {}),
    track: undefined,
    stage: undefined,
  }));

export const addTalks = (dispatch: AppDispatch) => (
  talks: TalkEntityResponse[],
) => {
  dispatch(
    talkStore.actions.updateResources({
      resources: talksWithEntityToIds(talks),
    }),
  );

  const tracks = talks
    .map((talk) => talk.track)
    .filter((track) => !!track) as TrackEntity[];
  tracks.length > 0 &&
    dispatch(trackStore.actions.updateResources({ resources: tracks }));

  const stages = talks
    .map((talk) => talk.stage)
    .filter((stage) => stage) as StageEntity<string>[];
  stages.length && addStages(dispatch)(stages);
};

export const talkService = ({ apiService, dispatch }: CommonServiceDeps) => ({
  create: (data: Talk, id: string) =>
    apiService.post<TalkEntity>(`talks/${id}`, data).then((response) => {
      addTalks(dispatch)([response]);
      return response;
    }),
  get: (id: string) =>
    apiService
      .get<TalkEntityExtended>(`talks/single/${id}`)
      .then((response) => {
        dispatch(talkStore.actions.updateResources({ resources: [response] }));
        return response;
      }),
  update: (data: Talk, id: string) =>
    apiService.patch(`talks/${id}`, data).then((response) => {
      dispatch(talkStore.actions.updateResources({ resources: [response] }));
      return response;
    }),
  delete: (id: string) =>
    apiService.delete(`talks/${id}`).then((response) => {
      dispatch(talkStore.actions.deleteResource({ id }));
      return response;
    }),
  getTalkViews: (id: string) =>
    apiService.get<{ views: number }>(`talks/views/${id}`),
  getTopTalks: () => apiService.get<TalkEntity[]>("talks/top?count=8"),
  cover: {
    // TODO: Refactor
    create: ({ id, file }: CreateCoverDTO) => {
      const formData = new FormData();
      formData.append("picture", file);
      return apiService.post(`talks/cover/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    delete: (id: string) => apiService.delete(`talks/cover/${id}`),
  },
});

import React from "react";
import { NavLink } from "react-router-dom";
import { app } from "config";

import Icons from "components/Icons";

export const Footer = () => {
  return (
    <footer>
      <a
        href={app.detailsURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icons.QuestionCircle size="1rem" />
        FAQ
      </a>

      <NavLink to="/privacy">
        <Icons.CookieBite size="1rem" />
        Privacy Policy
      </NavLink>

      <NavLink to="/terms">
        <Icons.OpenBook size="1rem" />
        Terms and Conditions
      </NavLink>
    </footer>
  );
};

import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { Tabs, Tooltip } from "antd";
import { Bookmark } from "react-iconly";
import { Resources } from "app/presentation/dashboard/stage/resources";
import { SpeakerInformation } from "app/presentation/dashboard/stage/speakerInformation";
import { ReplayTalkEntityExtended } from "app/infra/replayTalk";
import { ReplayTrackEntity } from "app/infra/replayTrack";
import { Status } from "app/infra/talk";
import { Player } from "app/common/views";

import { PlayerService } from "app/common/views/player/playerService";
import VimeoPlayer from "@vimeo/player";
import { connect } from "react-redux";
import { RootState } from "services";
import { country_list } from "app/common/data";
import { ClickedOnceProvider, DiContext, EPubSubTable, isCountryInAsia, Tracking } from "app/common";
import { StreamingProvider, videoStreamStore } from "app/infra/videoStream";

const ProviderDescription = {
  [StreamingProvider.VIMEO]: "Vimeo provider.",
  [StreamingProvider.JW]: "JW provider (best for Asian countries).",
  [StreamingProvider.S3]: "AWS provider.",
  [StreamingProvider.DACAST]: "Dacast provider (best for Asian countries).",
};

const ProviderName = {
  [StreamingProvider.VIMEO]: "VIMEO",
  [StreamingProvider.JW]: "JW",
  [StreamingProvider.S3]: "S3",
  [StreamingProvider.DACAST]: "DACAST",
};


const defaultProviders = [
  StreamingProvider.VIMEO,
  StreamingProvider.JW,
  StreamingProvider.S3,
  StreamingProvider.DACAST,
] as StreamingProvider[];

const asiaProviders = [
  StreamingProvider.JW,
  StreamingProvider.VIMEO,
  StreamingProvider.S3,
  StreamingProvider.DACAST,
] as StreamingProvider[];

const createVimeoPlayer = (id: string, controls: boolean, url: string) => {
  try {
    if (!VimeoPlayer) {
      return dummyPlayer;
    }

    const player = new VimeoPlayer("stage-player", {
      title: false,
      url,
      controls,
      height: 500,
      autoplay: false,
    });

    return {
      play: (offset, volume) => {
        try {
          if (offset) player?.setCurrentTime(offset);
          player?.play();
          player?.setVolume(volume / 100);
        } catch (e) {
          console.error(e);
        }
      },
      pause: () => {
        try {
          player?.pause();
        } catch (e) {
          console.error(e);
        }
      },
      currentTime: async () => {
        return player?.getCurrentTime().then((time: any) => parseInt(time));
      },
      isPlaying: () => {
        return false;
      },
      setVolume: (volume: number) => {
        try {
          player?.setVolume(volume / 100);
        } catch (e) {
          console.error(e);
        }
      },
    } as PlayerService;
  } catch (e) {
    return dummyPlayer;
  }
};

interface ContentProps {
  talk: ReplayTalkEntityExtended;
  me: any;
  selectedProvider: StreamingProvider,
}

const dummyPlayer = {
  play: () => {},
  pause: () => {},
  currentTime: async () => {
    return 0;
  },
  isPlaying: () => false,
  setVolume: () => {},
} as PlayerService;

const createJwPlayer = (playerId: string, controls: boolean, link: string) => {
  try {
    // @ts-ignore
    if (!window.jwplayer) {
      return dummyPlayer;
    }

    // @ts-ignore
    const player = window.jwplayer(playerId).setup({
      file: link,
      height: 500,
      controls,
    });

    return {
      play: (offset: number, volume: number) => {
        try {
          if (offset) player.seek(offset);
          player.play();
          player.setVolume(volume);
        } catch (e) {
          console.error(e);
        }
      },
      pause: () => {
        try {
          player.stop();
        } catch (e) {
          console.error(e);
        }
      },
      currentTime: async () => {
        return player?.getCurrentTime();
      },
      isPlaying: () => false,
      setVolume: (volume: number) => {
        try {
          player?.setVolume(volume);
        } catch (e) {
          console.error(e);
        }
      },
    } as PlayerService;
  } catch (e) {
    return dummyPlayer;
  }
};

const createDacastPlayer = ({
  playerId,
  link,
}: {
  playerId: string;
  controls: boolean;
  link: string;
}) => {
  try {
    // @ts-ignore
    if (!window.dacast) {
      return dummyPlayer;
    }

    // @ts-ignore
    const player = window.dacast(link, playerId);

    return {
      play: (offset: number, volume: number) => {
        try {
          player.play();
          player.volume(volume / 100);
          if (offset) player.currentTime(offset);
        } catch (e) {
          console.error(e);
        }
      },
      pause: () => {
        try {
          player.pause();
        } catch (e) {
          console.error(e);
        }
      },
      currentTime: async () => {
        return new Promise((resolve) => {
          player?.getTime((time: any) => {
            resolve(parseInt(time));
          });
        });
        // return player?.getCurrentTime();
      },
      isPlaying: () => {
        try {
          return player.playing();
        } catch (e) {
          console.error(e);
        }
      },
      setVolume: (volume: number) => {
        try {
          player?.volume(volume / 100);
        } catch (e) {
          console.error(e);
        }
      },
    } as PlayerService;
  } catch (e) {
    return dummyPlayer;
  }
};

export const Content = connect((state: RootState) => ({
  me: state.userStore.byId.me,
  volume: state.videoStreamStore.volume,
  selectedProvider: state.videoStreamStore.provider,
}))(
  ({
    talk,
    me,
    selectedProvider,
  }: ContentProps) => {
    const [hasDoneAutoselect, setHasDoneAutoselect] = useState(false);
    const [firstPaint, setFirstPaint] = useState(true);
    const [player, setPlayer] = useState<PlayerService>(dummyPlayer);

    const { clicked } = useContext(ClickedOnceProvider);
    const { dispatch } = useContext(DiContext);

    const [providerActive, setProvider] = useState([
      {
        name: "VIMEO",
        is_loaded: false,
      },
      {
        name: "JW",
        is_loaded: false,
      },
      {
        name: "S3",
        is_loaded: false,
      },
    ]);
    const history = useHistory();

    const changeStreamByCountry = useCallback(
      (country: string) => {
        if (country_list.length && country) {
          const isCountry = country_list.includes(country);

          if (isCountry && !hasDoneAutoselect) {
            if (isCountryInAsia(country)) {
              dispatch(videoStreamStore.actions.update({ provider: StreamingProvider.JW }));
              setHasDoneAutoselect(true);
            }
          }
        }
      },
      [hasDoneAutoselect]
    );

    const handlePrrovidersActive = (index: any, value: boolean) => {
      let dataProviders = [...providerActive];
      dataProviders[index].is_loaded = value;
      setProvider(dataProviders);
    };

    useEffect(() => {
      const getCurrentCountry = me?.country;
      changeStreamByCountry(getCurrentCountry);

      let eventName: string = "";
      const eventProps: any = {
        videoId: talk.id,
        category: StreamingProvider[selectedProvider],
      };
      if (status !== null) {
        // stream view will be fired at interval
        eventName = "Video View";
      }
      let isViewing: boolean = false;

      // const getLoadedProvider = providerActive.find((i) => i.is_loaded);
      if (player) {
        player.pause();
      }

      let loadSeconds = 0;
      if (firstPaint) {
        setTimeout(() => {
          setFirstPaint(false);
        }, 100);

        setPlayer(dummyPlayer);
      } else if (selectedProvider === StreamingProvider.JW) {
        setPlayer(
          createJwPlayer(
            "stage-player",
            true,
            talk.video_url_4
            // "https://content.jwplatform.com/videos/WfxCynLL-1y2sBa7e.mp4"
          )
        );

        isViewing = true;
      } else if (selectedProvider === StreamingProvider.S3) {
        setPlayer(
          createJwPlayer(
            "stage-player",
            true,
            talk.video_url_3
            // "https://awc21may-dev.s3-accelerate.amazonaws.com/video_1.mp4"
          )
        );

        isViewing = true;
      } else if (selectedProvider === StreamingProvider.VIMEO) {
        setPlayer(
          createVimeoPlayer(
            "stage-player",
            true,
            talk.video_url_2
            // "//vimeo.com/474829957"
          )
        );

        isViewing = true;
      } else if (selectedProvider === StreamingProvider.DACAST) {
        setPlayer(
          createDacastPlayer({
            link: talk.video_url,
            // "29cf7a47-7b3a-d1b6-5ff0-4a76ed22a202-vod-9c311e73-bade-800b-fecf-1ac52d29b672",
            playerId: "stage-player",
            controls: true,
          })
        );
        isViewing = true;
      } else {
        setPlayer(dummyPlayer);
      }

      if (isViewing) {
        Tracking.Analytics(me, eventName, eventProps);
        // Tracking.PubSub([{
        //   table: EPubSubTable.Video,
        //   data: {
        //     datetime: Tracking.Date,
        //     user_id: me.id as string,
        //     type: "replay",
        //     platform: ProviderName[selectedProvider],
        //     timestamp: 0,
        //     video_id: talk.id,
        //   },
        // }]);
      }

    }, [selectedProvider, talk, firstPaint, status, clicked]);

    useEffect(() => {
      if (talk.video_url && talk.video_url_2) {
      } else if (talk.video_url) {
        dispatch(videoStreamStore.actions.update({ provider: StreamingProvider.JW }));
      } else if (talk.video_url_2) {
        dispatch(videoStreamStore.actions.update({ provider: StreamingProvider.VIMEO }));
      }
    }, [talk.id]);

    const selectedStreamingProviderOrder = useMemo(() => {
      return (
        (isCountryInAsia(me?.country) && asiaProviders) || defaultProviders
      );
    }, [me?.country]);

    return (
      <div className="content-container">
        <div
          key={selectedProvider}
          id="player-container"
          style={{
            height: 500,
            background: "black",
          }}
        >
          <Player
            showPlay={!clicked}
            player={player}
            videoId={talk.video_url}
            offset={0}
            status={Status.finished}
            identifier={talk.id}
            cover={talk.cover_url}
            stage={talk.stage}
            onComplete={() => {}}
          />
        </div>
        <div className="additional-info-container">
          {talk.video_url &&
            talk.video_url_2 && (
              <div className="stream-list-wrapper">
                <div className="stream-list">
                  {selectedStreamingProviderOrder.map((provider, index) => (
                    <Tooltip
                      className={
                        (selectedProvider === provider && "selected") || ""
                      }
                      title={ProviderDescription[provider]}
                    >
                      <div className="stream-box" onClick={() => dispatch(videoStreamStore.actions.update({ provider }))}>
                        Stream {index + 1}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </div>
            )}
          <div>
            <h2
              style={{
                maxWidth: 900,
              }}
            >
              {talk.title}
            </h2>
          </div>

          {talk.resources && talk.resources.length > 0 && (
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane
                key="1"
                tab={
                  <>
                    <Bookmark set="bulk" />

                    Downloadable Resources
                  </>
                }
              >
                <Resources resources={talk.resources || []} />
              </Tabs.TabPane>
            </Tabs>
          )}

          {talk.speakers?.map((user) => (
            <div style={{ margin: "30px 0" }} key={user.id}>
              <SpeakerInformation
                user={user}
                fullname={`${user.first_name} ${user.last_name}`}
                position={`${user.job_position || ""}, ${
                  user.company?.name || user.attendee_company || ""
                } `}
                bio={talk.description}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
);

import React from "react";
import { Link } from "react-router-dom";
import { UserEntity } from "app/infra/user";
import { Col, Tag } from "antd";
import Icons from "components/Icons";
import { UserAvatar } from "components/User/UserAvatar";

interface PersonCardProps {
  person: UserEntity;
  simpleActionButton?: boolean;
  me?: UserEntity;
}

export const PersonCard = ({ person, me }: PersonCardProps) => {
  return (
    <Col sm={24} md={12} style={{ width: "100%" }}>
      <Link to={`/app/user-profile/${person.id}`} className="person-card">
        <div className="content">
          <div className="card-header">
            <UserAvatar user={person} style={{ marginRight: "15px" }} />

            <div className="user-info-header">
              <div className="user-info-name">
                {person.first_name} {person.last_name}{" "}

                {(person.roles.includes("ROLE_SPEAKER") || person.isPromoted) && (
                  <Icons.TickRound primaryColor="#0CC2FF" size={20} style={{ marginLeft: "5px" }} />
                )}
              </div>
              <div className="subtitle">
                {person.job_position && (
                  <span className="user-info-job">
                    {(person.company && ` @   ${person.company.name}`)
                      || (person.attendee_company && ` @   ${person.attendee_company}`)
                      || ""}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="user-info">
            <div className="tag-container">
              {person.traffic && person.traffic.length > 0 && (
                <div className="tags tags-interested" style={{ flex: 1 }}>
                  <span className="tag-title">Interisted in :</span>
                  <div className="tags-wrapper">
                    {person.interested.map((topic) => (
                      <Tag key={`interested-in-${topic}`} className={`ant-tag-small ${topic}`}>
                        {topic}
                      </Tag>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </Col>
  );
};

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "qs";

import { SegmentedControl } from "app/common";

import { Row, Col } from "antd";
import { User, Work, Voice2 } from "react-iconly";

import { CompanyCardList } from "components/Company/CompanyCardList";
import UserList from "components/User/UserList";
import { CompanyShortList } from "components/Company/CompanyShortList";

enum ListTypes {
  MARKETPLACE = "MARKETPLACE",
  ATTENDEE = "ATTENDEES",
  SPEAKERS = "SPEAKERS",
}

interface DirectoryPageQueryParams {
  type?: ListTypes;
}

const items = [
  {
    text: ListTypes.MARKETPLACE,
    Icon: () => <Work set="bold" />,
  },
  {
    text: ListTypes.ATTENDEE,
    Icon: () => <User set="bold" />,
  },
  {
    text: ListTypes.SPEAKERS,
    Icon: () => <Voice2 set="bold" />,
  },
];

export const DirectoryPage = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = parse(location.search, { ignoreQueryPrefix: true }) as DirectoryPageQueryParams;
  const selectedList = queryParams.type;

  const setSelectedList = (type: string) => {
    history.push(`/app/directory?type=${type}`);
  };

  useEffect(() => {
    if (!queryParams.type) {
      setSelectedList(ListTypes.ATTENDEE);
    }
  }, [queryParams]);

  return (
    <div className="directory-page">
      <SegmentedControl
        items={items}
        selected={selectedList}
        setSelected={setSelectedList}
      />

      <div className="directory-page-main">
        <Row gutter={20}>
          <Col xl={selectedList === ListTypes.MARKETPLACE ? 24 : 18}>
            <div className="main-content">
              <div className="company-wrapper">
                {selectedList === ListTypes.MARKETPLACE && (
                  <CompanyCardList />
                )}

                {selectedList === ListTypes.ATTENDEE && (
                  <UserList
                    keystamp="attendee-directory-page"
                    role="ROLE_ATTENDEE"
                    noFoundText="No results found. Try searching in the Speakers tab."
                  />
                )}

                {selectedList === ListTypes.SPEAKERS && (
                  <UserList
                    keystamp="directory-page-speakers"
                    role="ROLE_SPEAKER"
                    noFoundText="No results found. Try searching in the Attendee tab."
                  />
                )}
              </div>
            </div>
          </Col>

          {selectedList !== ListTypes.MARKETPLACE && (
            <Col xl={6}>
              <div className="extra-information-wrapper">
                <div className="extra-infromation-header">
                  <h2 className="extra-infromation-wrapper-title">
                    Recommended
                  </h2>
                </div>

                <CompanyShortList />
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

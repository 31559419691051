import React, { ReactElement, useContext, useMemo, useState } from "react";
import { Button, Dropdown, Menu, Modal } from "antd";
import { StageEntity } from "app/infra/stage";
import { TalkEntity } from "app/infra/talk";
import { connect } from "react-redux";
import { RootState } from "services";
import { ChevronDown, ChevronUp } from "react-iconly";
import { scheduleService } from "app/infra/schedule";
import { useHistory } from "react-router";
import { DiContext, useAsync } from "app/common";
import { isBefore, isAfter, addMinutes, addSeconds } from "date-fns";
import { useOnError } from "hooks/useOnError";

import { google, outlook, office365, yahoo, ics, CalendarEvent } from "calendar-link";
import fileDownload from "js-file-download";

import { AppModes, mode } from "app/infra/app";

import { TrackEntity } from "../../../infra/track";
import { Status, Talk } from "./talk";

interface TalkModalOwnProps {
  visible: boolean;
  onClose: () => void;
  selectedTalk: TalkEntity;
  timezone: string;
}

interface TalkModalProps extends TalkModalOwnProps {
  stages: StageEntity<string>[];
  myTalks: string[];
  track: TrackEntity;
  isScheduleFinalized: boolean;
}

export const TalkModal = connect(
  (state: RootState, ownProps: TalkModalOwnProps) => ({
    myTalks: state.scheduleStore.myTalks,
    stages: Object.values(state.stageStore.byId),
    track: state.trackStore.byId[ownProps.selectedTalk.track_id],
  }),
)(
  ({
    visible,
    onClose,
    selectedTalk,
    myTalks,
    stages,
    timezone,
    track,
    isScheduleFinalized,
  }: TalkModalProps) => {
  const history = useHistory();

  const { apiService, dispatch } = useContext(DiContext);
  const scheduleSrv = scheduleService({ apiService, dispatch });

  const [status, setStatus] = useState<Status | null>(null);

  const isOnboardingMode = mode === AppModes.onboarding;

  const setStatusFromTalk = () => {
    const current = Date.now();
    if (isBefore(current, Date.parse(selectedTalk.start_time))) {
      setStatus(Status.future);
    } else {
      setStatus(Status.stable);
    }
  };

  const { pending, execute, error } = useAsync((add: boolean) => {
    return add
      ? scheduleSrv.addTalkToMySchedule(selectedTalk.id)
      : scheduleSrv.removeTalkFromMySchedule(selectedTalk.id);
  });

  useOnError(error);

  const isBreak = (value: string) => {
    const regex = /\b(break)$/i.exec(value) || [];
    return regex.length > 0 && value.split(" ").length < 3;
  };

  const ToggleFromScheduleButton = (): ReactElement => {
    return (
      <Button
        type="primary"
        disabled={pending}
        onClick={() => { return execute(!myTalks.includes(selectedTalk.id)); }}
        loading={pending}
        style={{
          marginLeft: "25px",
          height: "50px",
          borderRadius: "10px",
          padding: "4px 22px",
        }}
      >
        {myTalks.includes(selectedTalk.id) ? "Remove from My Schedule" : "Add to My Schedule"}
      </Button>
    );
  };

  const VisitEventButton = (): ReactElement => {
    return (
      <Button
        type="primary"
        disabled={pending}
        onClick={() => { return history.push(`/app/talk/${selectedTalk.id}`); }}
        className="schedule-modal-main-btn"
        style={{
          marginLeft: "25px",
          height: "50px",
          borderRadius: "10px",
          padding: "4px 22px",
        }}
      >
        Watch talk
      </Button>
    );
  };

  const event = useMemo((): CalendarEvent => {
    return {
      title: selectedTalk.title,
      description: selectedTalk.description,
      start: selectedTalk.start_time,
      duration: [selectedTalk.length, "minutes"],
    };
  }, [selectedTalk.title, selectedTalk.description, selectedTalk.start_time, selectedTalk.length]);

  const downloadICS = () => {
    const data = decodeURIComponent(ics(event).replace("data:text/calendar;charset=utf8,", ""));
    fileDownload(data, `${selectedTalk.title}.ics`, "text/calendar");
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href={google(event)}>
          Google
        </a>
      </Menu.Item>

      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href={outlook(event)}>
          Outlook
        </a>
      </Menu.Item>

      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href={office365(event)}>
          Office365
        </a>
      </Menu.Item>

      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href={yahoo(event)}>
          Yahoo
        </a>
      </Menu.Item>

      <Menu.Item>
        <Button className="ics-btn" type="link" onClick={downloadICS} style={{ padding: 0, height: 24 }}>
          iCalendar File
        </Button>
      </Menu.Item>
    </Menu>
  );

  const [addToCalendarOpened, setAddToCalendarOpened] = useState(false);
  const addToCalendar = (): ReactElement | null => {
    if (selectedTalk.description !== "Stage Closed") {
      return (
        <Dropdown
          overlay={menu}
          placement="bottomRight"
          className="calendar-link"
          onVisibleChange={(dropVisible) => setAddToCalendarOpened(dropVisible)}
        >
          <Button
            type="default"
            className="schedule-modal-add-to-calendar-btn"
            style={{
              height: "50px",
              borderRadius: "10px",
              padding: "4px 10px",
            }}
          >
            <div style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "5px",
            }}
            >
              Add to calendar
              { addToCalendarOpened
                ? <ChevronUp style={{ marginLeft: "27px" }} />
                : <ChevronDown style={{ marginLeft: "27px" }} />}
            </div>
          </Button>
        </Dropdown>
      );
    }
    return null;
  };

  const addMySchedule = (): ReactElement | null => {
    // if (
    //   selectedTalk.hasNetworkingMixer
    //   // && isBefore(Date.now(), addMinutes(new Date(selectedTalk.start_time), selectedTalk.length))
    //   // && isAfter(Date.now(), addSeconds(new Date(selectedTalk.start_time), -2))
    // ) {
    //   return (
    //     <Button
    //       type="primary"
    //       disabled={pending}
    //       onClick={() => { return window.open(`/app/chats/${selectedTalk.chat_channel_id}`); }}
    //       className="schedule-modal-main-btn"
    //       style={{
    //         marginLeft: "25px",
    //         height: "50px",
    //         borderRadius: "10px",
    //         padding: "4px 22px",
    //       }}
    //     >
    //       Access Networking Mixer
    //     </Button>
    //   );
    // }

    if (status === Status.future) {
      return <ToggleFromScheduleButton />;
    }

    if (selectedTalk.video_url_2) {
      return <VisitEventButton />;
    }

    return null;
  };

  const getFooter = () => {
    if (isBreak(selectedTalk.title)) {
      return null;
    }
    return [
      // addToCalendar(),
      addMySchedule(),
    ];
  };

    return (
      <Modal
        visible={visible}
        onCancel={onClose}
        centered
        className="talk-modal"
        footer={getFooter()}
      >
        <Talk
          id={selectedTalk.id}
          title={selectedTalk.title}
          time={selectedTalk.start_time}
          description={selectedTalk.description}
          speakers={selectedTalk.speakers}
          length={selectedTalk.length}
          track={track}
          stage={selectedTalk.stage_id}
          start_time={selectedTalk.start_time}
          offset={selectedTalk.offset}
          hasDiscussion={selectedTalk.has_discussion}
          isFinished={selectedTalk.is_finished}
          myTalks={myTalks}
          stages={stages}
          timezone={timezone}
          status={status}
          setStatusFromTalk={setStatusFromTalk}
          hasNetworkingMixer={selectedTalk.hasNetworkingMixer}
        />
      </Modal>
    );
  },
);

import React from "react";
import { useIubenda } from "hooks";

export const PrivacyPage = () => {
  useIubenda();

  return (
    <div style={{ background: "#fff", padding: "0 25px" }}>
      <a href="https://www.iubenda.com/privacy-policy/7909489" className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Privacy Policy">Privacy Policy</a>
    </div>
  );
};

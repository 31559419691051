import React from "react";
import { CompanyShortList } from "components/Company/CompanyShortList";
import UserShortList from "components/User/UserShortList";
import { COMPANY } from "config/constants";

export const ExtraInformation = () => {
  return (
    <div className="extra-information">
      <div className="extra-information-recommended extra-information-attendees">
        <div className="extra-information-header">
          <div className="extra-information-title">Recommended Attendees</div>
        </div>

        <UserShortList />
      </div>

      <div className="extra-information-recommended extra-information-companies">
        <div className="extra-information-header">
          <div className="extra-information-title">{`Recommended ${COMPANY.meta.name}s`}</div>
        </div>
        <CompanyShortList />
      </div>
    </div>
  );
};

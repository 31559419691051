import { CommonServiceDeps } from "app/common";
import { AxiosRequestConfig } from "axios";
import { RaffleEntity } from "./RaffleEntity";
import { raffleStore } from "./RaffleStore";

export interface RafflePhoto {
  id: string;
  photo: string | Blob;
}

export type Raffle = Pick<RaffleEntity, "name" | "date" | "price" | "imageURL">;

export interface IRaffleService {
  getAll: () => Promise<RaffleEntity[]>;
  create: (raffle: Raffle) => Promise<RaffleEntity>;
  delete: (id: string) => Promise<void>;
  update: (id: string, raffle: Raffle) => Promise<RaffleEntity>;
  updatePhoto: (rafflePhoto: RafflePhoto) => Promise<RaffleEntity>;
  claim: (id: string) => Promise<RaffleEntity[]>;

  getRaffleStats: (config: AxiosRequestConfig) => Promise<string | ArrayBuffer | ArrayBufferView | Blob>;
}

export const raffleService = (props: CommonServiceDeps): IRaffleService => {
  return {
    getAll: () => {
      return props.apiService
        .get("raffles")
        .then((response: RaffleEntity[]) => {
          props.dispatch(raffleStore.actions.add({ resource: response }));
          props.dispatch(raffleStore.actions.addKeyIds({
            key: "lobby-raffles",
            resource: response.map((raffle) => raffle.id.toString()),
          }));
          return response;
        });
    },
    create: (raffle: Raffle) => {
      return props.apiService
        .post<RaffleEntity>("raffles", raffle)
        .then((response) => {
          props.dispatch(raffleStore.actions.add({ resource: [response] }));
          return response;
        });
    },
    delete: (id: string) => {
      return props.apiService
        .delete<void>(`raffles/${id}`)
        .then(() => {
          props.dispatch(raffleStore.actions.delete({ id }));
        });
    },
    update: (id: string, raffle: Raffle) => {
      return props.apiService
        .patch<RaffleEntity>(`raffles/${id}`, raffle)
        .then((response: RaffleEntity) => {
          props.dispatch(raffleStore.actions.add({ resource: [response] }));
          return response;
        });
    },
    updatePhoto: (rafflePhoto: RafflePhoto) => {
      const formData = new FormData();
      formData.append("picture", rafflePhoto.photo);
      return props.apiService
        .post<RaffleEntity>(`raffles/image/${rafflePhoto.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    },
    claim: (id: string) => {
      return props.apiService
        .post<RaffleEntity[]>(`raffles/${id}/entries`)
        .then((response) => {
          return response;
        });
    },
    getRaffleStats: (config: AxiosRequestConfig = {}) => {
      return props.apiService.post("/stats/raffles", {}, config);
    },
  };
};

export const companyType = [
  "Creator Marketplace",
  "Service Provider & Apps",
  "Course & Membership Platform",
  "Agencies & Marketing",
  "Business Operations",
  "Investor",
  "Other",
];

export const attendeeCompanyType = [
  "Creator",
  "Brand",
  "Service Provider",
  "Digital Marketer",
  "Entrepreneur",
  "Freelancer",
  "Agency",
  "Investor",
  "Other",
];

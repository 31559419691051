import { CommonServiceDeps, PaginationDTO } from "app/common";
import { userStore } from "./user.store";
import type { UserEntity } from "./user.entity";

interface UpdateConnectionsDataDTO {
  connectionsLeft: number;
  dailyLimit: number;
}

export type AdminUser = Pick<UserEntity,
  "first_name"
  | "last_name"
  | "email"
  | "roles"
  | "companyDescription"
  > & {
    company: string,
    companyId: string,
    password?: string,
  };

interface GetUsersDTO extends PaginationDTO {
  search?: string;
  pageSize?: number;
}

export const adminUserService = ({
  dispatch,
  apiService,
}: CommonServiceDeps) => ({
  getAll: ({ page, pageSize, search }: GetUsersDTO) =>
    apiService.get("admin/users", {
      params: {
        page,
        count: pageSize,
        ...((search && { search }) || {}),
      },
    }),
  create: (data: AdminUser) =>
    apiService.post("admin/create-user", data).then((response) => {
      const user = response as UserEntity;
      dispatch(userStore.actions.addUsers({ resources: [user] }));
      return user;
    }),
  update: (data: AdminUser, id: string) =>
    apiService.patch(`admin/update-user/${id}`, data).then((response) => {
      dispatch(
        userStore.actions.addUsers({ resources: [response as UserEntity] })
      );
      return response;
    }),
  getSpeakers: () =>
    apiService.get("/admin/speakers").then((speakers) => {
      dispatch(userStore.actions.addUsers({ resources: speakers }));
      return speakers;
    }),
  deletePhoto: (id: string) =>
    apiService.delete(`admin/picture/${id}`).then((response) => {
      const user = { ...response, id } as UserEntity;
      dispatch(userStore.actions.addUsers({ resources: [user] }));
      return user;
    }),
  updatePhoto: (photo: string | Blob, id: string) => {
    const formData = new FormData();
    formData.append("picture", photo);
    return apiService
      .patch(`admin/picture/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const user = { ...response, id } as UserEntity;
        dispatch(userStore.actions.addUsers({ resources: [user] }));
        return user;
      });
  },
  resendConfirmationLink: (id: string) =>
    apiService.post(`admin/users/${id}/resend-invitation-link`),
  resendForgotPasswordEmail: (id: string) =>
    apiService.post(`admin/users/${id}/resend-forgot-password-link`),
  getForgotPasswordLink: (id: string) =>
    apiService.get<{ link: string }>(`admin/users/${id}/forgot-password-link`),
  updateTicket: (id: string, deactivate_sync: boolean) =>
    apiService.patch(`admin/tickets/${id}`, {
      deactivate_sync,
    }),
  getUser: (id: string) => apiService.get<UserEntity>(`admin/users/${id}`),
  updateConnectionsData: (data: UpdateConnectionsDataDTO, userId: string) =>
    apiService.patch<void>(`admin/users/${userId}/connection-limit`, data),
  syncTicket: (ticketId: string) =>
    apiService.post(`admin/tickets/${ticketId}`),
});

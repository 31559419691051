import React, {
  useMemo,
  useState,
  useEffect,
  ReactElement,
  useContext,
} from "react";
import { app } from "config";

import { connect } from "react-redux";
import { RootState } from "services";

import moment from "moment-timezone";
import ical from "ical-generator";
import fileDownload from "js-file-download";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import isAfter from "date-fns/isAfter";
import { subDays } from "date-fns";

import { useModal } from "hooks/useModal";
import { useSessionState } from "hooks/useSessionState";

import { AppContext } from "components/Layout/Layout";

import { StageEntity } from "app/infra/stage";
import { TalkEntity } from "app/infra/talk";
import { TrackEntity } from "app/infra/track";

import { Button, Table, Tooltip, Switch, Tag } from "antd";
import { Danger, PaperDownload, TickSquare, TimeCircle, TimeSquare } from "react-iconly";
import { Loading } from "components/Loading/Loading";

import Icons from "components/Icons";
import { UserAvatar } from "components/User/UserAvatar";
import { AppModes, mode } from "app/infra/app";
import { TalkModal } from "./talk.modal";

interface TimeLineProps {
  daysConfTime: string[];
  daysUserTime: string[];
  tracks: { [key: string]: TrackEntity };
  stages: StageEntity<string>[];
  events: TalkEntity[];
}

interface OwnProps {
  currentMethod: string;
  currentEvents: string[];
  selectedTracksArray: string[];
  liveQA: boolean;
  liveMixer: boolean;
  query: string;
}

interface DataSourceObject {
  [index: number]: React.ReactElement[];
}

interface ScheduleProps extends OwnProps, TimeLineProps {}

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps,
): TimeLineProps => {
  let events: TalkEntity[];

  if (ownProps.currentMethod === "Schedule") {
    events = Object.values(state.talkStore.byId).map((talk) => ({
      ...talk,
      isAddedToMySchedule: state.scheduleStore.myTalks.includes(talk.id),
    }));
  } else {
    events = state.scheduleStore.myTalks.map(
      (talk) => state.talkStore.byId[talk],
    );
  }

  return {
    daysConfTime: state.scheduleStore.daysConfTime,
    daysUserTime: state.scheduleStore.daysUserTime,
    tracks: state.trackStore.byId,
    stages: Object.values(state.stageStore.byId),
    events,
  };
};

export const Schedule = connect(mapStateToProps)((props: ScheduleProps) => {
  const [isLocalizedTimezone, setIsLocalizedTimezone] = useSessionState("schedule:isLocalizedTimezone", true);
  const [selectedDayIndex, setSelectedDayIndex] = useSessionState("schedule:selectedDayIndex", -1);

  const [selectedEvent, setSelectedEvent] = useState<TalkEntity | null>(null);
  const [isICSDownloaded, setIsICSDownloaded] = useState(false);
  const [isPDFDownloaded, setIsPDFDownloaded] = useState(false);

  const localizedTimezone = moment.tz.guess();

  const currentTimezone = useMemo(() => {
    return isLocalizedTimezone ? localizedTimezone : app.confTimezone;
  }, [isLocalizedTimezone]);

  const days = useMemo(() => {
    return isLocalizedTimezone ? props.daysUserTime : props.daysConfTime;
  }, [isLocalizedTimezone, props.daysUserTime, props.daysConfTime]);

  const selectedDay = useMemo(() => {
    return isLocalizedTimezone
      ? props.daysUserTime[selectedDayIndex]
      : props.daysConfTime[selectedDayIndex];
  }, [isLocalizedTimezone, selectedDayIndex, days]);

  useEffect(() => {
    if (selectedDayIndex === -1) {
      const today = moment.tz(currentTimezone).format("D MMMM");
      const index = days.findIndex((day) => day === today);
      setSelectedDayIndex(index === -1 ? 0 : index);
    }
  }, []);

  useEffect(() => {
    if (selectedDayIndex >= days.length && days.length !== 0) {
      setSelectedDayIndex(days.length - 1);
    }
  }, [days]); // when props.daysLocalTime.length !== props.daysCloudTime.length

  const modal = useModal();

  const isOnboardingMode = mode === AppModes.onboarding;

  const events = useMemo(() => {
    let entries = props.events.filter(
      (event) => moment(event.start_time).tz(currentTimezone).format("D MMMM") === selectedDay,
    );

    if (props.liveQA === true) {
      entries = entries.filter((entry) => {
        return entry.has_discussion === true;
      });
    }

    if (props.liveMixer === true) {
      entries = entries.filter((entry) => {
        return entry.hasNetworkingMixer === true;
      });
    }

    if (props.selectedTracksArray.length > 0) {
      entries = entries.filter((entry) => {
        return props.selectedTracksArray.includes(entry.track_id.toString());
      });
    }

    if (props.query !== "") {
      const strings = props.query.toLowerCase().split(" ");
      entries = entries.filter((entry) => {
        const title = entry.title.toLowerCase();
        const description = entry.description.toLowerCase();

        return strings.reduce((acc: boolean, value: string) => {
          if (acc) return acc;
          return (
            value !== ""
            && (title.search(value) !== -1 || description.search(value) !== -1)
          );
        }, false);
      });
    }

    return entries.sort(
      (a, b) => Date.parse(a.start_time) - Date.parse(b.start_time),
    );
  }, [
    props.selectedTracksArray,
    props.query,
    props.tracks,
    props.events,
    selectedDay,
    currentTimezone,
  ]);

  const getColumns = () => {
    return [
      {
        title: () => {
        },
        width: 50,
        dataIndex: "timeSlot",
        key: "timeSlot",
        className: " ant-cell-no-right-border ant-cell-event-not-ended ",
      },
      ...props.stages.map((stage, index) => {
        let borderRadius = "";
        if (index === 0) {
          borderRadius = " ant-cell-border-top-left-radius ";
        } else if (index === props.stages.length - 1) {
          borderRadius = " ant-cell-border-top-right-radius ";
        }
        return {
          title: (
            <div key={stage.id} className="stage-header">
              <span>{stage.name}</span>
            </div>
          ),
          dataIndex: stage.id,
          key: stage.id,
          width: 340,
          className: borderRadius,
        };
      }),
    ];
  };

  const viewEvent = (event: TalkEntity): void => {
    setSelectedEvent(event);
    modal.open();
  };

  const isStageBreak = (event: TalkEntity) => {
    return event.speakers?.map((speaker) => `${speaker.first_name} ${speaker.last_name}`).join(", ").toLocaleLowerCase().includes("stage break");
  };

  const isStageClosed = (event: TalkEntity) => {
    return event.title.toLowerCase().includes("stage closed");
  };

  const drawEvent = (event: TalkEntity, borderTop: boolean) => {
    const startTime = moment(event.start_time).tz(currentTimezone);
    const nowTime = moment(new Date()).tz(currentTimezone);
    const isLive = !!(nowTime.isAfter(startTime) && !event.is_finished);

    return (
      <div
        role="button"
        tabIndex={0}
        className={`agenda-item ${event.video_url_2 ? "ant-cell-event-not-ended" : ""} ${isStageBreak(event) || isStageClosed(event) ? "ant-cell-stage-break" : ""}`}
        onKeyDown={() => {
          if (!isStageClosed(event)) {
            viewEvent(event);
          }
        }}
        onClick={() => {
          if (!isStageClosed(event)) {
            viewEvent(event);
          }
        }}
        style={{
          borderTop: borderTop ? "1px solid #d7dae2" : "",
        }}
      >
        <div className="agenda-item-img" style={{ zIndex: 2 }}>
          {event.speakers && (
            <UserAvatar user={event.speakers[0]} size={70} style={{ marginRight: "15px" }} />
          )}
        </div>

        <div className="agenda-item-content" style={{ zIndex: 2 }}>
          <div style={{ display: "flex" }}>
            <h5 className="agenda-item-content-title">{event.title}</h5>

            <div
              style={{
                display: "flex",
                height: "max-content",
                marginLeft: "auto",
                paddingLeft: "10px",
              }}
              data-html2canvas-ignore={true}
            >
              {/* {isLive && !isStageClosed(event) && !isStageBreak(event) && (
                <Tag
                  className="ant-tag-small"
                  style={{
                    color: "#F01E6A",
                    background: "rgba(240, 30, 106, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "0",
                  }}
                >
                  <svg viewBox="0 0 100 100" height="15" width="15">
                    <circle cx="50" cy="50" r="20" fill="#F01E6A" />
                    <circle cx="50" cy="50" r="40" fill="#F01E6A66" />
                  </svg>
                  Live
                </Tag>
              )} */}

              {event.has_discussion && !isStageClosed(event) && !isStageBreak(event) && (
                <Tag
                  className="ant-tag-small"
                  style={{
                    color: "#F01E6A",
                    background: "rgba(240, 30, 106, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "0",
                    marginLeft: "2px",
                  }}
                >
                  <svg viewBox="0 0 100 100" height="15" width="15">
                    <circle cx="50" cy="50" r="20" fill="#F01E6A" />
                    <circle cx="50" cy="50" r="40" fill="#F01E6A66" />
                  </svg>
                  Q&A
                </Tag>
              )}

              {event.hasNetworkingMixer && !isStageClosed(event) && !isStageBreak(event) && (
                <Tag
                  className="ant-tag-small"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#F01E6A",
                    background: "rgba(240, 30, 106, 0.2)",
                    marginRight: "0",
                    marginLeft: "2px",
                  }}
                >
                  <svg viewBox="0 0 100 100" height="15" width="15">
                    <circle cx="50" cy="50" r="20" fill="#F01E6A" />
                    <circle cx="50" cy="50" r="40" fill="#F01E6A66" />
                  </svg>
                  Networking Mixer
                </Tag>
              )}
            </div>
          </div>

          <span className="agenda-item-content-time">
            {!isStageClosed(event) && (
              <h6>
                {event.speakers?.length && event.speakers.map((speaker) => (
                  <span key={`agenda-item-content-name-${speaker.id}`} style={{ marginRight: "5px" }}>
                    {speaker.first_name} {speaker.last_name}
                  </span>
                ))}
              </h6>
            )}

            {!isStageBreak(event) && !isStageClosed(event) && props.tracks[event.track_id] && (
              <Tag className="ant-tag-small" style={{ background: props.tracks[event.track_id].color }}>
                {props.tracks[event.track_id].name}
              </Tag>
            )}

            {/* <div className="schedule-time-on-off">
              <TimeCircle
                set="bold"
                size={18}
                primaryColor="#8C8F98"
                style={{ marginRight: "5px", verticalAlign: "middle" }}
              />

              <span style={{ verticalAlign: "middle", fontSize: "14px", color: "#8C8F98" }}>
                {moment(event.start_time).tz(currentTimezone).format("H:mm")}
                {" "}-{" "}
                {moment(event.start_time).add(event.length, "minutes").tz(currentTimezone).format("H:mm")}
              </span>
            </div> */}

            {(event as TalkEntity & { isAddedToMySchedule: boolean }).isAddedToMySchedule && (
              <span
                className="agenda-item-content-added-to-my-schedule"
                data-html2canvas-ignore={true}
              >
                <TickSquare /> Added to my schedule
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  const getDataSource = () => {
    return Object.entries(
      events.reduce(
        (
          acc: { [key: string]: { [key: string]: ReactElement[] } },
          event: TalkEntity,
        ) => {
          const startTime = moment(event.start_time).tz(currentTimezone);
          const hh = startTime.get("hour");
          const mm = startTime.get("minute") >= 30 ? "30" : "00";
          const key = `${hh}:${mm}`;

          if (acc[key]) {
            const stage = acc[key][event.stage_id];
            if (stage !== undefined) {
              stage.push(drawEvent(event, true));
              return acc;
            }

            acc[key][event.stage_id] = [
              ...(acc[key][event.stage_id] || []),
              drawEvent(event, false),
            ];

            return acc;
          }

          acc[key] = {
            [event.stage_id]: [drawEvent(event, false)],
          };

          return acc;
        },
        {},
      ),
    ).map((entry, entryIndex) => {
      const dataSourceObject = {} as DataSourceObject;
      Object.entries(entry[1]).forEach(([stageId, entries]) => {
        dataSourceObject[parseInt(stageId, 10)] = [
          <div
            key={`schedule-table-cell-${entryIndex}`}
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
            }}
          >
            {entries}
          </div>,
        ];
      });

      return {
        timeSlot: <div key={`schedule-table-cell-time-${entryIndex}`} className="agenda-table-time schedule-time-on-off" style={{ display: "flex" }}>{entry[0]}</div>,
        ...dataSourceObject,
      };
    });
  };

  const downloadICS = () => {
    const title = `${app.name} ${props.currentMethod.toLowerCase()}`;
    const calendar = ical({ name: title });

    setIsICSDownloaded(true);

    props.events.forEach((event) => {
      if (isStageBreak(event)) {
        calendar.createEvent({
          start: moment(event.start_time),
          end: moment(event.start_time).add(event.length, "minutes"),
          summary: event.title,
          description: event.description,
        });
      }
    });

    fileDownload(calendar.toString(), `${title}.ics`, "text/calendar");
    setTimeout(() => {
      setIsICSDownloaded(false);
    }, 500);
  };

  const downloadPDF = () => {
    const element = document.querySelector(".ant-table") as HTMLElement;
    const filename = `${days[selectedDayIndex]} Schedule - ${app.name}.pdf`;

    setIsPDFDownloaded(true);

    html2canvas(element, {
      useCORS: true,
      allowTaint: true,
      logging: false,
    })
      .then((canvas: HTMLCanvasElement) => {
        document.body.appendChild(canvas);
        const image = canvas.toDataURL("image/png");
        const width = element.offsetWidth;
        const height = element.offsetHeight;
        const margin = 10;
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF("portrait", "px", [
          width + margin * 2,
          height + margin * 2,
        ]);

        pdf.addImage(image, "PNG", margin, margin, width, height);
        pdf.save(filename);

        canvas.remove();
        setIsPDFDownloaded(false);
      })
      .catch(console.error);
  };

  const { confOpenTime } = useContext(AppContext);
  const isScheduleFinalized = useMemo(() => {
    return isAfter(Date.now(), subDays(Date.parse(confOpenTime), 2));
  }, [confOpenTime]);

  const getDayFromIndex = (index: number) : string => {
    if (index === 0) return " Day One ";
    if (index === 1) return " Day Two ";
    if (index === 2) return " Day Three ";
    if (index === 3) return " Day Four ";
    return " not found ";
  };

  const abbrs: { [key: string]: string } = {
    "EST": "Eastern Standard Time",
    "EDT": "Eastern Daylight Time",
    "CST": "Central Standard Time",
    "CDT": "Central Daylight Time",
    "MST": "Mountain Standard Time",
    "MDT": "Mountain Daylight Time",
    "PST": "Pacific Standard Time",
    "PDT": "Pacific Daylight Time",
  };

  const confTimezoneAbbr = "EST";

  return (
    <div className="schedule">
      <div className="time-selection">
        <div className="dates">
          {days.map((day: string, index: number) => (
            <div
              key={`dates-${day}`}
              role="button"
              tabIndex={0}
              className={`date ${(selectedDayIndex === index && "selected") || ""}`}
              onKeyDown={() => {
                setSelectedDayIndex(index);
              }}
              onClick={() => {
                setSelectedDayIndex(index);
              }}
            >
              <span>
                {getDayFromIndex(index)}
                <br />
              </span>

              <span style={{ fontWeight: "normal", paddingBottom: "0px" }}>
                {day}
              </span>
            </div>
          ))}
        </div>

        <div className="timezone-container schedule-time-on-off">
          <span className="timezone-title">
            <Tooltip title="Time Zone" placement="bottom">
              <TimeCircle set="bold" primaryColor="#4e5964" style={{ verticalAlign: "middle" }} />
            </Tooltip>
          </span>

          <div className="timezone-switch">
            <span className="timezone-name">
              <Tooltip title={moment.tz.guess()} placement="bottom">
                Local
                <span className="local-time-zone">
                  {localizedTimezone.split("/").slice(-1)}
                </span>
              </Tooltip>
            </span>

            <Switch
              className="con-switch"
              checked={!isLocalizedTimezone}
              onChange={() => setIsLocalizedTimezone((prev) => !prev)}
              style={{ margin: "0px 8px" }}
            />

            <span className="timezone-name">
              <Tooltip title={abbrs[confTimezoneAbbr] || moment.tz(app.confTimezone).zoneName()} placement="bottom">
                Conference
                <span className="conference-time-zone">
                  {confTimezoneAbbr}
                </span>
              </Tooltip>
            </span>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            {isPDFDownloaded ? (
              <Loading />
            ) : (
              <Tooltip title={`Download PDF - Schedule ${getDayFromIndex(selectedDayIndex)}`} placement="bottomRight">
                <Button
                  type="link"
                  onClick={downloadPDF}
                  icon={<PaperDownload set="bold" size={29} />}
                >

                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      <div id="schedule-table" style={{ margin: "0px 51px 31px 51px" }}>
        <Table
          locale={{
            emptyText: (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Danger set="bold" size={35} />
                {props.currentMethod === "Schedule"
                ? "No results found! Please be sure to search all Stages and Days"
                : "No speeches added to your schedule"}
              </div>
            ),
          }}
          columns={getColumns()}
          dataSource={getDataSource()}
          bordered={true}
          pagination={false}
          rowKey={(record) => (`schedule-table-row-key-${record.timeSlot.key || ""}`)}
        />
      </div>

      {selectedEvent && (
        <TalkModal
          visible={modal.isVisible}
          onClose={() => {
            setSelectedEvent(null);
          }}
          selectedTalk={selectedEvent}
          timezone={currentTimezone}
          isScheduleFinalized={isScheduleFinalized}
        />
      )}
    </div>
  );
});

import React from "react";
import { isMobile } from "react-device-detect";
import Icons from "components/Icons";

interface FullScreenIconsProps {
  isFullScreen: boolean;
  setFullScreen: (val: boolean) => void;
}

export const ExitFullScreenIcon = ({
  isFullScreen,
  setFullScreen,
}: FullScreenIconsProps) => {
  return (
    <Icons.Compress
      style={{
        width: 25,
        height: 25,
        position: "absolute",
        bottom: isMobile ? 30 : 12,
        right: isMobile ? 30 : 12,
      }}
      className={`fullscreen-control ${isFullScreen && "show"}`}
      onClick={() => {
        setFullScreen(false);
      }}
    />
  );
};

export const EnterFullScreenIcon = ({
  isFullScreen,
  setFullScreen,
}: FullScreenIconsProps) => (
  <Icons.Expand
    style={{
      width: 25,
      height: 25,
      position: "absolute",
      bottom: 12,
      right: 12,
    }}
    className={`fullscreen-control ${!isFullScreen && "show"}`}
    onClick={() => {
      setFullScreen(true);
    }}
  />
);

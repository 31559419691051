import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { TalkEntityExtended } from "app/infra/talk";
import { AppModes, mode } from "app/infra/app";
import { Play } from "react-iconly";
import { ReplayTalkEntityExtended } from "app/infra/replayTalk";
import { isDarkColor } from "app/common/utils/isDarkColor";
import { Tag } from "antd";

interface TalkItemOwnProps {
  talk: TalkEntityExtended | ReplayTalkEntityExtended;
}

interface TalkItemProps extends TalkItemOwnProps {}

export const TalkItem = ({ talk }: TalkItemProps) => {
  const coverUrl = useMemo(() => {
    return talk.cover_url;
  }, [talk.cover_url]);

  return (
    <Link
      className="talk-item"
      to={`/app/replay/${talk.id}`}
    >
      <div
        className="talk-content"
        style={{
          backgroundImage: `url(${coverUrl})`,
          borderRadius: 4,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="icon-play">
          <Play set="bold" size="large" primaryColor="white" />
        </div>
      </div>
      <h3>
        {talk.title}
      </h3>
      {talk.track && (
        <Tag
          style={{
            background: talk?.track?.color,
            color: isDarkColor(talk?.track?.color || "#fff") ? "white" : "black",
          }}
        >
          {talk.track.name}
        </Tag>
      )}
    </Link>
  );
};

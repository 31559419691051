import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

// Taken from font-awesome source code
const SendMessage = (props: BasicProps): ReactElement => {
  return (
      <div style={{
          width: props.bgSize,
          height: props.bgSize,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
      }}>
        <svg
            className={`send-message-icon ${props.className || ""}`}
            style={{
            width: props.size,
            height: props.size,
            fill: props.primaryColor,
            verticalAlign: "-0.125em",
            cursor: "pointer",
            ...props.style,
            }}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.833374 10.0138C0.833374 5.1849 4.69254 0.833374 10.0184 0.833374C15.225 0.833374 19.1667 5.10228 19.1667 9.98627C19.1667 15.6506 14.5467 19.1667 10 19.1667C8.49671 19.1667 6.82837 18.7628 5.49004 17.9733C5.02254 17.6887 4.62837 17.4775 4.12421 17.6428L2.27254 18.1936C1.80504 18.3405 1.38337 17.9733 1.52087 17.4775L2.13504 15.4211C2.23587 15.1365 2.21754 14.8335 2.07087 14.5948C1.28254 13.1443 0.833374 11.5561 0.833374 10.0138ZM8.80837 10.0138C8.80837 10.6656 9.33087 11.1889 9.9817 11.1981C10.6325 11.1981 11.155 10.6656 11.155 10.023C11.155 9.37118 10.6325 8.84789 9.9817 8.84789C9.34004 8.83871 8.80837 9.37118 8.80837 10.0138ZM13.0342 10.023C13.0342 10.6656 13.5567 11.1981 14.2075 11.1981C14.8584 11.1981 15.3809 10.6656 15.3809 10.023C15.3809 9.37118 14.8584 8.8479 14.2075 8.8479C13.5567 8.8479 13.0342 9.37118 13.0342 10.023ZM5.75589 11.1981C5.11422 11.1981 4.58255 10.6656 4.58255 10.023C4.58255 9.37118 5.10505 8.8479 5.75589 8.8479C6.40672 8.8479 6.92922 9.37118 6.92922 10.023C6.92922 10.6656 6.40672 11.1889 5.75589 11.1981Z"
            fill="#A0A4B9"
            />
        </svg>
      </div>
  );
};

SendMessage.defaultProps = {
  size: 20,
  bgSize: 30,
  primaryColor: "currentColor",
};

export default SendMessage;

import * as company from "./company/countries";
import { ConstantConfig, USER } from "./user";

export const COMPANY = Object.freeze({
  meta: {
    name: "Exhibitor",
  },

  name: {
    name: "name",
    label: "Exhibitor Name",
    rules: [
      {
        required: true,
        message: "First Name is required",
      },
    ],
  } as ConstantConfig,

  email: {
    name: "email",
    label: "Exhibitor Public Email",
    rules: [
      {
        type: "email",
        message: "Not a valid email address",
      },
    ],
  } as ConstantConfig,

  location: {
    name: "country",
    label: "Location",
    rules: [
      {
        required: true,
        message: "Location is required",
      },
    ],
    data: company.countries,
  } as ConstantConfig,

  type: {
    name: "company_type",
    label: "Exhibitor Type",
    rules: [
      {
        required: true,
        message: "Exhibitor Type is required",
      },
    ],
    data: [
      "Service Provider",
      "Network",
      "Agency",
      "Advertiser",
      "Traffic Source",
    ],
  } as ConstantConfig,

  expertise: {
    name: "expertise",
    label: "Select Areas of Speciality",
    rules: [
      {
        required: true,
        message: "Please select at least 1 area of specialty",
      },
    ],
    data: [
      "Native",
      "Lead Generation",
      "Apps",
      "Dating",
      "Pop-ups",
      "Video",
      "Monetization",
      "Services",
      "Search",
      "Social",
      "Ecommerce",
    ],
  } as ConstantConfig,

  bio: {
    name: "description",
    label: "Exhibitor Bio",
    placeholder: "Write a short bio (max 240 characters)",
    rules: [
      {
        max: 1000,
        message: "Exhibitor Bio cannot be longer than 240 characters",
      },
      {
        required: true,
        message: "Exhibitor Bio is required",
      },
    ],
  } as ConstantConfig,

  website: {
    ...USER.website,
    name: "url",
    rules: [
      {
        required: true,
        message: "Website is required",
      },
      {
        type: "url",
        message: "Not a valid URL",
      },
    ],
  } as ConstantConfig,

  facebook: {
    name: "facebook",
    label: "Facebook",
    placeholder: "Facebook Profile",
    rules: [
      {
        type: "url",
        message: "Not a valid URL",
      },
      {
        validator: (_, value: string) => {
          if (!value || /^https:\/\/(www.|mobile.|m.|touch.|mbasic.)?(facebook.com|fb.me)\/([\w#!?=/.%-]*)/.test(value)) {
            return Promise.resolve();
          }

          return Promise.reject();
        },
        message: "Not a valid Facebook URL",
      },
    ],
  } as ConstantConfig,

  twitter: {
    name: "twitter",
    label: "Twitter",
    placeholder: "Twitter Profile",
    rules: [
      {
        type: "url",
        message: "Not a valid URL",
      },
      {
        validator: (_, value: string) => {
          if (!value || /^https:\/\/(www.|mobile.|m.)?twitter.com\/(@?[\w]{1,15})\/?/.test(value)) {
            return Promise.resolve();
          }

          return Promise.reject();
        },
        message: "Not a valid Twitter URL",
      },
    ],
  } as ConstantConfig,

  focus: USER.focus,

  traffic: {
    ...USER.traffic,
    rules: [
      {
        required: true,
        type: "array",
        min: 1,
        message: "You must select at least 1 traffic interest",
      },
    ],
  } as ConstantConfig,

  geoTraffic: {
    ...USER.geoTraffic,
    rules: [
      {
        required: true,
        type: "array",
        min: 1,
        message: "You must select at least 1 geo traffic",
      },
    ],
  } as ConstantConfig,

  promoVideo: {
    name: "promo_video",
    label: "Promo Video (Vimeo Link)",
    placeholder: "https://vimeo.com/xxxxxxxxx",
    rules: [
      {
        type: "url",
        message: "Link must be a valid URL and include https://",
      },
      {
        validator: (_rule, value, callback) => {
          if (value) {
            if (/https?:\/\/(www\.)?vimeo\.com\/\d+/.test(value)) {
              callback();
            } else {
              callback("Not a valid Vimeo link");
            }
          } else {
            callback();
          }
        },
      },
    ],
  } as ConstantConfig,
});

import React, { useMemo, useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { app } from "config";

import { connect } from "react-redux";
import { RootState } from "services";

import moment from "moment-timezone";

import { isMobile } from "react-device-detect";

import { DiContext } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnMount } from "hooks/useOnMount";

import { AppModes, mode } from "app/infra/app";
import { logout } from "app/infra/auth";
import { UserEntity, userService } from "app/infra/user";
import { PushNotificationEntity, pushNotificationService } from "app/infra/pushNotification";
import { NotificationBareData } from "app/infra/pushNotification/pushNotification.store";
import { scheduleService } from "app/infra/schedule";

import { Badge, Button, Drawer, Dropdown, Layout, Menu } from "antd";
import {
  Calendar,
  Category,
  Graph,
  Logout,
  Notification,
  Star,
  Video,
} from "react-iconly";
import Icons from "components/Icons";

import logo from "assets/images/awe/logo/logo.svg";

import { UserAvatar } from "components/User/UserAvatar";
import { NotificationDropdown } from "./NotificationDropdown";

const { SubMenu } = Menu;

interface HeaderProps {
  me: UserEntity | null;
  isAdmin: boolean;
  unreadMessages: number;
  daysUserTime: string[];

  notifications: {
    latest: PushNotificationEntity | null;
    unread: NotificationBareData[];
  };
}

const mapStateToProps = (state: RootState): HeaderProps => {
  return {
    me: state.userStore.byId["me"],
    isAdmin: state.authStore.isAdmin,
    daysUserTime: state.scheduleStore.daysUserTime,
    unreadMessages: state.chatStore.unreadMessages,

    notifications: {
      latest: state.pushNotificationStore.latest,
      unread: state.pushNotificationStore.unread,
    },
  };
};

export const Header = connect(mapStateToProps)((props: HeaderProps) => {
  const { dispatch, apiService } = useContext(DiContext);

  const userSrv = userService({ apiService, dispatch });
  const scheduleSrv = scheduleService({ apiService, dispatch });
  const pushNotificationSrv = pushNotificationService({ apiService, dispatch });

  const [drawerVisible, setDrawerVisible] = useState(false);

  const { execute, isPending } = useAsync(() => {
    return Promise.all([
      userSrv.me(),
      scheduleSrv.getAgenda(),
    ]);
  });

  useOnMount(execute);

  const day = useMemo(() => {
    const index = props.daysUserTime.findIndex((date: string) => date === moment().format("D MMMM"));
    return index + 1;
  }, [props.daysUserTime]);

  const mainLink = useMemo(() => {
    return isMobile ? "/app/stage" : "/app/schedule";
  }, [props.isAdmin]);

  const location = useLocation();

  useEffect(() => {
    if (
      window.navigator.serviceWorker === undefined
      || window.Notification === undefined
      || window.Notification.permission !== "granted"
    ) {
      if (props.notifications.latest) {
        pushNotificationSrv.unread.getAll({ after: props.notifications.latest.send_at }).catch(console.error);
      } else {
        pushNotificationSrv.getAll({ page: 0, count: 20 }).catch(console.error);
      }
    }
  }, [location.pathname]);

  const notificationMenuItemRender = useMemo(() => {
    return (
      <Menu.Item
        className="notification-bell"
        icon={(
          <Dropdown
            overlay={<NotificationDropdown unreadNotifications={props.notifications.unread} />}
            trigger={["click"]}
            overlayStyle={{ position: "fixed" }}
            openClassName="fixed-notifications"
          >
            <a
              href="/"
              className="ant-dropdown-link notification-indicator"
              onClick={(e) => { e.preventDefault(); }}
            >
              <Notification set="bold" />
              <Badge
                count={props.notifications.unread.length}
                style={{ fontWeight: "bold", boxShadow: "none", top: "-14px", right: "-25px" }}
              />
            </a>
          </Dropdown>
        )}
      />
    );
  }, [props.notifications.unread]);

  // const chatMenuItemRender = useMemo(() => {
  //   return (
  //     <Menu.Item>
  //       <NavLink to="/app/chats" onClick={() => setDrawerVisible(false)}>
  //         <Badge
  //           count={props.unreadMessages}
  //           overflowCount={99}
  //           offset={[17, 0]}
  //           style={{ background: "#F0136A", fontWeight: "bold", boxShadow: "none" }}
  //         >
  //           <Chat set="bold" />
  //           <span style={{ verticalAlign: "middle" }}>
  //             Chat
  //           </span>
  //         </Badge>
  //       </NavLink>
  //     </Menu.Item>
  //   );
  // }, [props.unreadMessages]);

  if (isPending) return null;

  const getLogo = () => {
    return (
      <Menu.Item className="logo">
        <NavLink to={mainLink} onClick={() => window.scroll({ top: 0, behavior: "smooth" })}>
          <img src={logo} alt={app.name} height={25} />
        </NavLink>

        {day ? (<span className="day-pill">{`Day ${day}`}</span>) : null}
      </Menu.Item>
    );
  };

  const getMenuItems = () => {
    return (
      <>
        <Menu.Item>
          <NavLink to="/app/stage" onClick={() => setDrawerVisible(false)}>
            <Video set="bold" />
            <span style={{ verticalAlign: "middle" }}>
              Replays
            </span>
          </NavLink>
        </Menu.Item>

        <Menu.Item>
          <NavLink to="/app/schedule?type=Schedule" onClick={() => setDrawerVisible(false)}>
            <Calendar set="bold" />
            <span style={{ verticalAlign: "middle" }}>
              Schedule
            </span>
          </NavLink>
        </Menu.Item>

        {(mode !== AppModes.onboarding || props.isAdmin) && (
          <Menu.Item>
            <a
              href="https://affiliateworldconferences.com/dubai/networking-app"
              target="_blank"
              onClick={() => setDrawerVisible(false)}
            >
              <Star set="bold" />
              <span style={{ verticalAlign: "middle" }}>
                Get Mobile App
              </span>
            </a>
          </Menu.Item>
        )}

        <Menu.Item>
          <NavLink to="/app/live" onClick={() => setDrawerVisible(false)}>
            <svg viewBox="0 0 100 100" height="20" width="20">
              <circle cx="50" cy="50" r="20" fill="#FFD955" />
              <circle cx="50" cy="50" r="40" fill="#FFD95566" />
            </svg>

            <span style={{ verticalAlign: "middle" }}>
              Live Stream
            </span>
          </NavLink>
        </Menu.Item>

        {props.isAdmin && (
          <Menu.Item>
            <NavLink to="/app/admin?type=SCHEDULE" onClick={() => setDrawerVisible(false)}>
              <Graph set="bold" />
              <span style={{ verticalAlign: "middle" }}>
                Admin
              </span>
            </NavLink>
          </Menu.Item>
        )}

        {!isMobile
          && (mode !== AppModes.onboarding || props.isAdmin)
          && (notificationMenuItemRender)}

        <SubMenu
          style={{ marginLeft: "0", border: "none" }}
          key="settings"
          popupOffset={[0, 0]}
          title={props.me && (
            <UserAvatar user={props.me} size={32} />
          )}
        >
          <Menu.Item
            key="setting:2"
            className="profile"
          >
            <a
              href={app.detailsURL}
              target="_blank"
              rel="noreferrer"
              onClick={() => setDrawerVisible(false)}
            >
              <Icons.QuestionCircle size="20px" />
              FAQ
            </a>
          </Menu.Item>

          <Menu.Item
            key="privacy"
            className="profile"
          >
            <NavLink to="/privacy" onClick={() => setDrawerVisible(false)}>
              <Icons.CookieBite size="20px" />
              Privacy Policy
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="terms"
            className="profile"
          >
            <NavLink to="/terms" onClick={() => setDrawerVisible(false)}>
              <Icons.OpenBook size="20px" />
              Terms and Conditions
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="setting:3"
            className="logout"
            onClick={() => { logout({ dispatch }); }}
          >
            <Logout set="bold" />
            Logout
          </Menu.Item>
        </SubMenu>
      </>
    );
  };

  return (
    <Layout.Header>
      <Menu
        mode="horizontal"
        className="header-full"
        selectedKeys={[location.pathname]}
      >
        {getLogo()}
        {getMenuItems()}
      </Menu>

      <Menu mode="horizontal" className="header-menu-right">
        {getLogo()}
        <Menu.Item>
          <Button
            type="text"
            icon={<Category set="bold" primaryColor="#ffffff80" />}
            onClick={() => setDrawerVisible(true)}
          />
        </Menu.Item>
      </Menu>

      <Drawer
        className="header-menu-right-drawer"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        closeIcon={() => {}}
      >
        <Menu mode="inline">
          {getMenuItems()}
        </Menu>
      </Drawer>
    </Layout.Header>
  );
});

import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { app } from "config";
import { parse } from "qs";

import { DiContext, useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { authService } from "app/infra/auth";

import { Button, Form, Input, notification } from "antd";
import { Lock } from "react-iconly";
import logo from "assets/images/awe/logo/logo-auth.svg";

interface ConfirmResetPasswordFormProps {
  id: string;
  token: string;
  password: string;
}

export const ConfirmResetPasswordPage = () => {
  const history = useHistory();
  const location = useLocation();

  const { apiService, dispatch } = useContext(DiContext);
  const authSrv = authService({ apiService, dispatch });

  const { execute, value, pending, error } = useAsync<{ ok?: boolean }>((data: ConfirmResetPasswordFormProps) => {
    return authSrv.resetPassword(data);
  }, false);

  useOnError(error);

  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (value && value.ok === true) {
      notification.success({
        message: "Use your new password to login. Redirecting!",
      });

      setTimeout(() => {
        history.push("/auth/login");
      }, 3000);
    }
  }, [value]);

  useEffect(() => {
    if (!queryParams.token || !queryParams.id) {
      history.push("/auth/login");
    }
  }, [queryParams.token, queryParams.id, error]);

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container">
        <Form
          layout="vertical"
          onFinish={execute}
        >
          <div className="logo">
            <img src={logo} alt={`${app.name} Logo`} height="40" />
          </div>

          <Form.Item
            name="token"
            initialValue={queryParams.token}
            style={{ display: "none" }}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="id"
            initialValue={queryParams.id}
            style={{ display: "none" }}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="password"
            className="password-form-item"
            rules={[
              {
                required: true,
                message: "Please enter your new password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter new password"
              prefix={<Lock set="light" />}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" className="auth-btn" htmlType="submit" loading={pending} block>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

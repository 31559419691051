import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const CheckIcon = (props: BasicProps): ReactElement => {
  return (
    <svg
      className={`adw-check-icon ${props.className || ""}`}
      style={{
        width: props.size,
        height: props.size,
        position: props.position,
        backgroundColor: props.secondaryColor,
        fill: "none",
        borderStyle: "solid",
        borderColor: props.secondaryColor,
        borderRadius: props.borderRadius,
        borderWidth: props.borderWidth,
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path d="M2 10.1667L7.93023 16L19 2" stroke={props.primaryColor} strokeWidth={props.weight} />
    </svg>
  );
};

CheckIcon.defaultProps = {
  size: 40,
  weight: "5",
  position: "relative",
  primaryColor: "white",
  secondaryColor: "#6BEDD4",
  borderRadius: "50%",
  borderWidth: "10px",
};

export default CheckIcon;

import React, { useContext, useEffect, useMemo, useState } from "react";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { attendeeInterests } from "app/core/user";
import { country_list } from "app/common/data";
import { useAsync, useDebounce, useOnError, useOnMount, useSessionState } from "hooks";
import { userService, userStore } from "app/infra/user";

import { Button, Input, Row } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import { Search } from "react-iconly";
import Icons from "components/Icons";
import { Loading } from "components/Loading/Loading";
import CheckboxFilter, { StringBooleanMap } from "components/CheckboxFilter/CheckboxFilter";

import { PersonCard } from "app/presentation/dashboard/directory/personCard";

interface Props extends PropsRedux {
  keystamp: string;
  role: string;
  noFoundText: string;
}

const UserList = (props: Props) => {
  const { apiService } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch: props.dispatch });

  const [filter, setFilter] = useSessionState("directory:search", "");
  const [selectedInterests, setSelectedInterests] = useSessionState("directory:interests", {});
  const [selectedCountries, setSelectedCountries] = useSessionState("directory:countries", {});

  const [page, setPage] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(false);

  const debouncedFilter = useDebounce(filter, 300);

  const usersList = useMemo(() => {
    const hasComponentAPagination = props.users[props.keystamp];

    if (hasComponentAPagination) {
      return hasComponentAPagination.map((key) => props.users[key]).flat().map((userId) => props.usersById[userId]);
    }
    return [];
  }, [props.users]);

  const interestList = useMemo(() => {
    return attendeeInterests.map((item) => {
      return {
        id: item,
        name: item,
      };
    });
  }, [attendeeInterests]);

  const countryList = useMemo(() => {
    return country_list.map((country) => {
      return {
        id: country,
        name: country,
      };
    });
  }, [country_list]);

  const getSelectedOptions = (strBoolMap: StringBooleanMap) => {
    return Object.entries(strBoolMap).filter((element) => element[1] === true).map((element) => element[0]);
  };

  const showClearAllFilters = useMemo(() => {
    return (
      filter?.length > 0
      || getSelectedOptions(selectedInterests).length > 0
      || getSelectedOptions(selectedCountries).length > 0
    );
  }, [filter, selectedInterests, selectedCountries]);

  const onClearAllFilters = () => {
    setFilter("");
    setSelectedCountries({});
    setSelectedInterests({});
  };

  const { execute, isPending, error } = useAsync((searchFilter: string, data?: { page: number }) => {
    return userSrv.getAll({
      limit: 20,
      page: data ? data.page : page,
      role: props.role,
      component: props.keystamp,
      search: searchFilter || undefined,
      interests: getSelectedOptions(selectedInterests),
      countries: getSelectedOptions(selectedCountries),
    }).then((response) => {
      setPage((prev) => prev + 1);

      if (response.data.length === 0 && response.pagination.page === 0) {
        setShowEmptyState(true);
      } else {
        setShowEmptyState(false);
      }

      return response;
    });
  });

  const getData = () => {
    if (!isPending) {
      execute(debouncedFilter);
    }
  };

  useOnMount(getData);
  useOnError(error);

  useEffect(() => {
    if (page !== 0) {
      props.dispatch(userStore.actions.addKeyedIds({ keyedstamp: props.keystamp, ids: [] }));
      setPage(0);
      execute(debouncedFilter, { page: 0 });
    }
  }, [debouncedFilter, selectedInterests, selectedCountries]);

  return (
    <>
      <div className="checkbox-filters">
        <div className="search-input">
          <Input
            className="adw-white-input"
            size="large"
            placeholder="Search"
            prefix={<Search set="light" primaryColor="#A3A7B2" size={20} />}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            suffix={filter && <Icons.Cancel onClick={() => setFilter("")} />}
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
          />
        </div>

        <div className="filter-right">
          {showClearAllFilters && (
            <Button className="clear-filters-btn" type="link" onClick={onClearAllFilters}>
              Clear Filters
            </Button>
          )}

          <CheckboxFilter
            placeholder="Filter by interest"
            selectedItems={selectedInterests}
            setSelectedItems={setSelectedInterests}
            items={interestList}
          />

          <CheckboxFilter
            placeholder="Filter by country"
            selectedItems={selectedCountries}
            setSelectedItems={setSelectedCountries}
            items={countryList}
          />
        </div>
      </div>

      {showEmptyState ? (
        <div className="directory-empty-state" style={{ textAlign: "center", marginTop: 90 }}>
          {props.noFoundText}
        </div>
      ) : (
        <div className="user-list">
          <InfiniteScroll
            next={getData}
            hasMore={true}
            loader={isPending && (
              <div key={0} style={{ marginTop: 20, textAlign: "center" }}>
                <Loading size={40} />
              </div>
            )}
            dataLength={usersList.length}
            style={{ overflow: "none" }}
          >
            <Row gutter={[16, 16]}>
              {usersList.map((user) => (
                <PersonCard key={user.id} person={user} me={props.me} />
              ))}
            </Row>
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.userStore.keyIds,
  usersById: state.userStore.byId,
  me: state.userStore.byId["me"],
});

const connector = connect(mapStateToProps);

type PropsRedux = ConnectedProps<typeof connector>;

export default connector(UserList);

import Cancel from "./CancelIcon/CancelIcon";
import Search from "./SearchIcon/SearchIcon";
import Check from "./CheckIcon";
import OpenBook from "./OpenBookIcon";
import CookieBite from "./CookieBiteIcon";
import QuestionCircle from "./QuestionCircle";
import CloudDownloadAlt from "./CloudDownloadAltIcon";
import Pause from "./PauseIcon";
import Expand from "./ExpandIcon";
import Compress from "./CompressIcon";
import LinkedIn from "./LinkedinIcon";
import Facebook from "./FacebookIcon";
import FacebookSimple from "./FacebookSimpleIcon";
import Twitter from "./TwitterIcon";
import Refresh from "./RefreshIcon";
import WaterDrop from "./WaterDropIcon";
import SendMessage from "./SendMessage";
import SyncCalendar from "./SyncCalendarIcon";
import Pin from "./PinIcon";
import TickRound from "./TickRound";

export {
  Cancel,
  Search,
  Check,
  OpenBook,
  CookieBite,
  QuestionCircle,
  CloudDownloadAlt,
  Pause,
  Expand,
  Compress,
  LinkedIn,
  Facebook,
  FacebookSimple,
  Twitter,
  Refresh,
  WaterDrop,
  SendMessage,
  SyncCalendar,
  Pin,
  TickRound,
};

const Icons = Object.freeze({
  Cancel,
  Search,
  Check,
  OpenBook,
  CookieBite,
  QuestionCircle,
  CloudDownloadAlt,
  Pause,
  Expand,
  Compress,
  LinkedIn,
  Facebook,
  FacebookSimple,
  Twitter,
  Refresh,
  WaterDrop,
  SendMessage,
  SyncCalendar,
  Pin,
  TickRound,
});

export default Icons;

import React, { useContext } from "react";
import { Link } from "react-router-dom";

import _ from "lodash";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync, useOnMount, useOnError } from "hooks";

import { CompanyEntity, companyService } from "app/infra/company";

import { Avatar, Skeleton } from "antd";
import { ChevronRightCircle, Work } from "react-iconly";

interface CompanyShortListProps {
  companies: CompanyEntity<string>[];
}

const component = "recommended-companies";

export const mapStateToProps = (state: RootState) => {
  const companyIds = _(state.companyStore.keyIds[component] || [])
    .flatMap((ref) => state.companyStore.keyIds[ref])
    .uniq()
    .take(10)
    .value();

  const companies = companyIds.map((id) => {
    return state.companyStore.companiesById[id];
  });

  return { companies };
};

export const CompanyShortList = connect(mapStateToProps)((props: CompanyShortListProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const { execute, isPending, error } = useAsync(() => {
    return companySrv
      .getCompanies({
        page: 0,
        limit: 100,
        component,
        isComplete: true,
      });
  });

  useOnMount(execute);
  useOnError(error);

  return (
    <div className="recommended-companies-section">
      <div className="company-list">
        {props.companies.length === 0 && isPending && (
          <>
            <Skeleton avatar={true} active={true} title={true} paragraph={{ rows: 2 }} />
            <Skeleton avatar={true} active={true} title={true} paragraph={{ rows: 2 }} />
            <Skeleton avatar={true} active={true} title={true} paragraph={{ rows: 2 }} />
          </>
        )}

        {props.companies.map((company) => (
          <Link to={`/app/booth/${company.id}`} className="company-item" key={company.id}>
            <div className="company-item-container">
              <Avatar
                size={60}
                src={company.logo_url}
                style={{ marginRight: "15px", ...(company?.logo_url ? {} : { backgroundColor: "#000000" }) }}
              >
                <Work set="bold" primaryColor="#FFFFFF" size={30} style={{ verticalAlign: "middle" }} />
              </Avatar>

              <div className="company-item-info">
                <h6>{company.name}</h6>

                <div className="subtitle">
                  <span>{_.truncate(company.description, { length: 50 })}</span>
                </div>
              </div>

              <ChevronRightCircle set="bold" style={{ alignSelf: "center", marginLeft: "auto" }} />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
});

import React from "react";
import { UserEntity } from "app/infra/user";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { RootState } from "services";
import { mode, AppModes } from "app/infra/app";

import { ChevronRightCircle } from "react-iconly";
import { UserAvatar } from "components/User/UserAvatar";

interface PersonItemProps {
  user: UserEntity;
  isAdmin: boolean;
  me?: UserEntity;
}

export const PersonItem = connect((state: RootState) => ({
  isAdmin: state.authStore.isAdmin,
  me: state.userStore.byId["me"],
}))(({ user, isAdmin }: PersonItemProps) => {
  return (
    <Link
      to={
        mode !== AppModes.onboarding || isAdmin
          ? `/app/user-profile/${user.id}`
          : "#"
      }
      className="contact-item"
    >
      <div className="left">
        <UserAvatar user={user} size={70} style={{ marginRight: "15px" }} />

        <div className="content">
          <h4>
            {user.first_name} {user.last_name}
          </h4>
        </div>
      </div>

      <ChevronRightCircle set="bold" style={{ alignSelf: "center", marginLeft: "auto" }} />
      {/* <div className="right">
        {(mode !== AppModes.onboarding || isAdmin) && (
          <div
            onClick={(e) => {
              e.preventDefault();
              execute(user.id);
            }}
          >
            {pending && <Loading size={32} />}
            {!pending && (
              <Chat
                set="bold"
                style={{
                  fontSize: "24px",
                  color: "#ced2d7",
                  marginLeft: "4.5px",
                  marginTop: "4.5px",
                }}
              />
            )}
          </div>
        )}
      </div> */}
    </Link>
  );
});

import { CommonServiceDeps, PaginatedResponse, PaginationRequestDTO } from "app/common";
import { PushNotificationStore } from "./pushNotification.store";
import type { PushNotificationEntity } from "./pushNotification.entity";

export type PushNotification = Pick<PushNotificationEntity,
  "title"
  | "body"
  | "link"
  | "send_at"
  >;

interface IPushNotificationService {
  getAll: (data: PaginationRequestDTO) => Promise<PaginatedResponse<PushNotificationEntity>>;
  create: (data: PushNotification) => Promise<PushNotificationEntity>;
  update: (data: PushNotification, id: string) => Promise<PushNotificationEntity>;
  delete: (id: string) => Promise<void>;
  forceSend: (id: string) => Promise<void>;
  admin: {
    getAll: (data: PaginationRequestDTO) => Promise<PaginatedResponse<PushNotificationEntity>>;
  };
  unread: {
    getAll: (data: PaginationRequestDTO | { after: string }) => Promise<PaginatedResponse<PushNotificationEntity>>;
  };
}

const getBareData = (notifications: PushNotificationEntity[]) => {
  return notifications.map((notification) => ({ [notification.id]: notification.send_at }));
};

export const pushNotificationService = (props: CommonServiceDeps): IPushNotificationService => {
  return {
    getAll: (data: PaginationRequestDTO) => {
      return props.apiService
        .get("/push-notifications", { params: data })
        .then((response) => {
          if (data.page === 0 && response.data) {
            props.dispatch(PushNotificationStore.actions.latest.create(response.data[0]));
          }
          return response;
        });
    },
    create: (data: PushNotification) => {
      return props.apiService.post("/push-notifications", data);
    },
    update: (data: PushNotification, id: string) => {
      return props.apiService.patch(`/push-notifications/${id}`, data);
    },
    delete: (id: string) => {
      return props.apiService.delete(`/push-notifications/${id}`);
    },
    forceSend: (id: string) => {
      return props.apiService.post(`/push-notifications/${id}/force-push`);
    },
    admin: {
      getAll: (data: PaginationRequestDTO) => {
        return props.apiService.get("/admin/push-notifications", { params: data });
      },
    },
    unread: {
      getAll: (data: PaginationRequestDTO | { after: string }) => {
        return props.apiService
          .get("/push-notifications", { params: { ...data, page: 0 } })
          .then((response: PaginatedResponse<PushNotificationEntity>) => {
            if (response.data) {
              props.dispatch(PushNotificationStore.actions.latest.create(response.data[0]));
              props.dispatch(PushNotificationStore.actions.unread.create(getBareData(response.data)));
            }

            return response;
          });
      },
    },
  };
};

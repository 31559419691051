import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const SyncCalendarIcon = (props: BasicProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`adw-sync-calendar-icon ${props.className || ""}`}
      style={{
        width: props.size,
        height: props.size,
        fill: props.primaryColor || "currentColor",
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <path
        d="M17.75 3C19.5449 3 21 4.45507 21 6.25V12.0218C20.5368 11.7253 20.0335
          11.4858 19.5 11.3135V8.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H11.3135C11.4858
          20.0335 11.7253 20.5368 12.0218 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507
          4.45507 3 6.25 3H17.75ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V7H19.5V6.25C19.5
          5.2835 18.7165 4.5 17.75 4.5Z"
      />
      <path
        d="M23 17.5C23 14.4624 20.5376 12 17.5 12C14.4624 12 12 14.4624 12 17.5C12 20.5376
          14.4624 23 17.5 23C20.5376 23 23 20.5376 23 17.5ZM14.5 14C14.7761 14 15 14.2239 15
          14.5V15.0506C15.6349 14.4027 16.5203 14 17.5 14C18.6677 14 19.7018 14.5724 20.3368
          15.4496C20.4987 15.6733 20.4487 15.9859 20.225 16.1478C20.0013 16.3098 19.6887 16.2597
          19.5268 16.036C19.0717 15.4073 18.3334 15 17.5 15C16.6826 15 15.9564 15.392 15.4997 16H16.5C16.7761
          16 17 16.2239 17 16.5C17 16.7761 16.7761 17 16.5 17H14.5C14.2239 17 14 16.7761 14 16.5V14.5C14 14.2239 
          14.2239 14 14.5 14ZM20 19.9494C19.3651 20.5973 18.4797 21 17.5 21C16.4268 21 15.4661 20.5164
          14.8248 19.7569C14.6467 19.546 14.6733 19.2305 14.8843 19.0523C15.0952 18.8742 15.4107 18.9008
          15.5889 19.1118C16.0482 19.6558 16.7337 20 17.5 20C18.3174 20 19.0436 19.608 19.5002 19H18.5C18.2239
          19 18 18.7761 18 18.5C18 18.2239 18.2239 18 18.5 18H20.5C20.7761 18 21 18.2239 21 18.5V20.5C21 20.7761
          20.7761 21 20.5 21C20.2239 21 20 20.7761 20 20.5V19.9494Z"
      />
    </svg>
  );
};

SyncCalendarIcon.defaultProps = {
  size: 16,
};

export default SyncCalendarIcon;

export const app = {
  apiUrl: process.env.REACT_APP_API_ENDPOINT,
  chatUrl: process.env.REACT_APP_CHAT_URL,
  landingURL: process.env.REACT_APP_LANDING_URL || "",
  detailsURL: process.env.REACT_APP_DETAILS_URL || "https://affiliateworldconferences.com/dubai/faq",
  confTimezone: process.env.REACT_APP_HOST_TIMEZONE || "America/New_York",
  name: process.env.REACT_APP_NAME || "Affiliate World",
  contactEmail: process.env.REACT_APP_CONTACT_EMAIL || "info@awconf.com",

  gcKey: process.env.REACT_APP_GC_KEY,
  gcUrl: process.env.REACT_APP_GC_URL,
};

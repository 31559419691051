import React, { useContext, useState, useEffect, useMemo } from "react";

import { connect } from "react-redux";
import { RootState } from "services";

import { uri } from "helpers/uri";

import { DiContext } from "app/common";
import { isDarkColor } from "app/common/utils/isDarkColor";

import { useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { companyService, CompanyEntity, TicketType } from "app/infra/company";
import { UserEntity } from "app/infra/user";
import { PerkEntity } from "app/infra/perk";

import { Avatar, Button, notification, Tag, Tooltip } from "antd";
import { Loading } from "components/Loading/Loading";
import { Location, Send, Message, Work } from "react-iconly";
import Icons from "components/Icons";

import { CompanyVideo } from "components/Company/CompanyVideo";
import { preventCopy } from "helpers";
import { PersonItem } from "./components/person.item";

interface CompanyProfilePageOwnProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface CompanyProfilePageProps extends CompanyProfilePageOwnProps {
  company?: CompanyEntity;
  profile?: UserEntity;
  perk?: PerkEntity | null;
}

export const BoothView = connect(
  (state: RootState, props: CompanyProfilePageOwnProps) => {
    const company = state.companyStore.companiesById[props.match.params.id];
    if (company) {
      return {
        profile: state.userStore.byId["me"],
        company: {
          ...company,
          users: company.users.map((userId) => state.userStore.byId[userId]),
        },
        perk: company.perk ? state.perkStore.byId[company.perk?.id] : null,
      };
    }

    return {};
  },
)(({ company, profile, perk, match }: CompanyProfilePageProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const [users, setUsers] = useState<UserEntity[]>([]);

  const { execute, error } = useAsync(() => {
    return Promise.all([
      companySrv.getCompany(match.params.id).then((response) => {
        return companySrv.getCompanyUsers(response.id).then((res) => {
          setUsers(res);
          return response;
        });
      }),
    ]);
  });

  const hasVideo = useMemo(() => {
    return company?.promo_video
      && company.promo_video !== ""
      && company?.ticket_type === TicketType.CompanySuperBooth;
  }, [company]);

  const hasTags = useMemo(() => {
    return company?.ticket_type !== TicketType.CompanyBasicBooth;
  }, [company]);

  const showSidebar = hasVideo || company?.perk;

  useOnError(error);

  useEffect(() => {
    execute();
  }, []);

  const url = useMemo(() => {
    return uri.parse(company?.url || "").toString();
  }, [company?.url]);

  const { execute: executeGetLead, value: isLead, error: executeGetLeadError } = useAsync(() => {
    return companySrv.getLead(match.params.id);
  });

  useOnError(executeGetLeadError);

  const message = `${company?.name || ""} has received your virtual business card they will be in touch soon.`;

  const { execute: executeLead, value: isClicked, error: executeLeadError } = useAsync(() => {
    return companySrv.createLead({ companyId: match.params.id }).then((response) => {
      notification.success({ message });
      return response;
    });
  });

  useOnMount(executeGetLead);
  useOnError(executeLeadError);

  const textColor = isDarkColor(company?.main_color || "") ? "white" : "black";

  return (
    <div className="booth-container">
      <div className="booth-view-container">
        <div className="left-container">
          <div className="booth-card">
            <div
              className="card-header"
              style={
                company
                  ? {
                    backgroundImage: `linear-gradient(#fff -500%, ${company.main_color})`,
                    backgroundColor: company.main_color,
                  }
                  : {}
              }
            >
              <div className="card-title">
                <div style={{ marginRight: "20px" }}>
                  <Avatar
                    src={company?.logo_url}
                    style={company?.logo_url ? {} : { backgroundColor: "#000000" }}
                    size={100}
                  >
                    <Work set="bold" primaryColor="#FFFFFF" size={60} style={{ verticalAlign: "middle" }} />
                  </Avatar>
                </div>

                <div className={`card-company-info ${isDarkColor(company?.main_color || "") ? "" : "inverted"}`}>
                  <h4
                    className="card-company-name"
                    onCopy={preventCopy}
                  >
                    {company?.name}
                  </h4>
                  <div className="card-company-description">
                    <span className="card-company-description-country">
                      {company?.country && (
                        <span
                          className="card-company-address"
                          style={{
                            color: textColor,
                          }}
                        >
                          <Location set="bold" />
                          <span>{company.country}</span>
                        </span>
                      )}
                    </span>
                    <span className="card-company-description-email">
                      <Message set="bold" />
                      {company?.email && (
                        <a
                          href={`mailto:${company.email}`}
                          className="company-email"
                          style={{
                            color: textColor,
                          }}
                        >
                          {company.email}
                        </a>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              {company?.url && (
                <div className="card-website">
                  <a href={url} target="_blank" rel="noreferrer">
                    Visit our website
                  </a>
                </div>
              )}
            </div>

            <div className="card-content">
              <h4
                onCopy={preventCopy}
              >
                Bio
              </h4>
              <p
                onCopy={preventCopy}
              >
                {company?.description}
              </p>

              {hasTags && (
                <div className="tags">
                  {company?.focus && company?.focus.map((item, index) => (
                    <div className="tag-item" key={index}>
                      <span className="check-icn">
                        <Icons.Check />
                      </span>
                      <div className="tag-title">{item}</div>
                    </div>
                  ))}
                </div>
              )}

              {company?.traffic && company?.traffic?.length ? (
                <div className="lk-for">
                  <p
                    onCopy={preventCopy}
                  >
                    Looking for:
                  </p>
                  {company?.traffic?.map((item, index) => (
                    <Tag className={`${item}`} key={index} style={{ background: "#000002", color: "#fff" }}>{item}</Tag>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="wrapper-container ">
          {showSidebar && (
            <div className="right-container">
              {hasVideo && (
                <div className="media-player-container">
                  {company?.promo_video ? (
                    <CompanyVideo vimeoURL={company?.promo_video} />
                  ) : (
                    <Loading size={24} />
                  )}
                </div>
              )}
            </div>
          )}
          {company?.ticket_type === TicketType.CompanySuperBooth && (
            <div className="business-card-container">
              <div className="text-left">
                <h4
                  onCopy={preventCopy}
                >
                  Can't talk now?
                </h4>
                <p
                  onCopy={preventCopy}
                >
                  Send Us Your Virtual Business Card
                </p>
              </div>

              <Tooltip placement="top" title={(!!isLead || !!isClicked) ? message : null}>
                <Button
                  type="primary"
                  className="send-button-wrapper"
                  disabled={!!isLead || !!isClicked}
                  onClick={executeLead}
                >
                  {!!isLead || !!isClicked ? "Already Sent" : "Send Now"}

                  <Send
                    style={{
                      transform: "rotate(45deg)",
                      alignSelf: "center",
                      marginLeft: "5px",
                    }}
                    filled={true}
                    set="light"
                    size="small"
                  />
                </Button>
              </Tooltip>
            </div>
          )}

          <div className="chat-container">
            <div className="contact-container">
              <h4
                className="title-hd"
                onCopy={preventCopy}
              >
                Chat with us
              </h4>

              <div className="contact-listing">
                {users.map((user) => {
                  return <PersonItem user={user} key={user.id} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

import { useEffect, useState } from "react";

interface UsePaginationProps {
  initialPageSize?: number;
  pagination?: {
    page: number;
    total: number;
  };
}

export interface FilterStructure {
  [key: string]: string;
}

export enum OrderEnum {
  DESC = "descend",
  ASC = "ascend",
  NONE = "",
}

export interface PaginationState {
  page: number;
  pageSize: number;
  totalItems: number;
  setPage: (page: number) => void;
  setFilter: (filter: any) => void;
  filter: string;
  order: OrderEnum;
  orderedBy: string;
  toggleOrderBy: (key: string) => void;
}

export const usePagination = ({
  initialPageSize = 20,
  pagination = { page: 0, total: 0 },
}: UsePaginationProps) => {
  const [filter, setFilter] = useState<FilterStructure>({});
  const [orderedBy, setOrderedBy] = useState("");
  const [order, setOrder] = useState<OrderEnum>(OrderEnum.NONE);

  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(initialPageSize);

  const toggleOrderBy = (key: string) => {
    setOrderedBy((prevOrderedBy: string) => {
      if (prevOrderedBy !== key) {
        setOrder((prevOrder: string) => {
          return OrderEnum.ASC;
        });
      } else {
        setOrder((prevOrder: string) => {
          switch (prevOrder) {
            case OrderEnum.ASC:
              return OrderEnum.DESC;
            case OrderEnum.DESC:
              return OrderEnum.NONE;
            case OrderEnum.NONE:
            default:
              return OrderEnum.ASC;
          }
        });
      }

      return key;
    });
  };

  useEffect(() => {
    if (pagination) {
      setTotalItems(pagination.total);
      setPage(pagination.page);
    }
  }, [pagination]);

  return {
    page,
    pageSize,
    totalItems,
    setPage,
    setFilter,
    filter,
    order,
    orderedBy,
    toggleOrderBy,
  };
};

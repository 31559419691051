import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";
import { useModal } from "hooks/useModal";

import { characterCountFormatter } from "helpers/characterCountFormatter";

import { DiContext } from "app/common";
import { isDarkColor } from "app/common/utils/isDarkColor";

import { CompanyEntity, companyService } from "app/infra/company";
import { UserEntity } from "app/infra/user";

import { TicketTypes } from "app/infra/ticketType";

import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  List,
  Modal,
  notification,
  PageHeader,
  Row,
  Select,
  Tabs,
} from "antd";

import { Show } from "react-iconly";
import { ColorResult, SketchPicker } from "react-color";

import Icons from "components/Icons";

import companyHeaderBackground from "assets/images/booths/marketplace-company-header-icon.svg";

import { COMPANY } from "config/constants";
import { MultiSelectCustomInput } from "components/MultiSelectCustomInput/MultiSelectCustomInput";
import { ImageUploadCompany } from "components/ImageUpload/ImageUploadCompany";
import { CompanyCard } from "./CompanyCard";

const { TabPane } = Tabs;

interface CompanyFormProps {
  company: CompanyEntity;
  user: UserEntity;
}

export const CompanyUpdateForm = (props: CompanyFormProps) => {
  const [form] = Form.useForm();
  const [isColorPickerShown, setIsColorPickerShown] = useState(false);
  const [background, setBackground] = useState(props.company.main_color || "#fff");

  const [preview, setPreview] = useState(props.company);
  const previewModal = useModal();

  const handleChange = (color: ColorResult) => {
    setBackground(color.hex);
    setPreview((obj) => ({ ...obj, main_color: color.hex }));
  };

  const handleChangeComplete = (color: ColorResult) => {
    setBackground(color.hex);
    setPreview((obj) => ({ ...obj, main_color: color.hex }));
  };

  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const { execute, isPending, error } = useAsync((data: any) => {
    data.main_color = background;
    return companySrv
      .updateCompany(data, props.company.id)
      .then((response) => {
        notification.success({ message: "Company was successfully updated" });
        return response;
      });
  });

  useOnError(error);

  const handleError = () => {
    notification.error({
      message: "Invalid Form",
      description: "Please fix all invalid fields to be able to save your changes",
    });
  };

  const history = useHistory();

  const handleTabClick = (key: string) => {
    if (key === "1") {
      history.push(`/app/user-profile/${props.user.old_id || props.user.id}/edit`);
    }
  };

  const accent = useMemo(() => {
    return isDarkColor(background) ? "white" : "black";
  }, [background]);

  const showPreview = useMemo(() => {
    return (
      <div>
        <Icons.Cancel
          style={{
            zIndex: 9999,
            cursor: "pointer",
            pointerEvents: "all",
            position: "absolute",
            top: "-10px",
            right: "-10px",
          }}
          primaryColor={accent}
          secondaryColor={accent === "white" ? "black" : "white"}
          size={26}
          onClick={previewModal.shut}
          className="ant-modal-cancel-icon"
        />

        <CompanyCard company={preview} />
      </div>
    );
  }, [preview, accent]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={execute}
      onFinishFailed={handleError}
    >
      <PageHeader
        ghost={false}
        extra={[
          <Button
            key="update-company-form-reset-info"
            type="link"
            icon={(
              <Icons.Refresh style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
            onClick={() => { form.resetFields(); }}
          >
            Reset Info
          </Button>,
          <Button key="update-company-form-save-changes" type="primary" htmlType="submit">Save Changes</Button>,
        ]}
        style={{ position: "sticky", top: "57px", width: "100%", zIndex: 999 }}
      />

      <Layout className="wrapper" style={{ marginTop: "40px" }}>
        <Tabs
          defaultActiveKey="2"
          tabBarGutter={40}
          tabBarStyle={{ borderBottom: 0 }}
          onTabClick={handleTabClick}
        >
          <TabPane
            key="1"
            tab="My Profile"
          />

          <TabPane
            key="2"
            tab={`${COMPANY.meta.name} Profile`}
          />
        </Tabs>

        <Row gutter={{ md: 20, lg: 40, xxl: 60 }}>
          <Col xs={24} lg={14} xl={12}>
            <Card style={{ marginBottom: "20px" }}>
              <h2>Company Info</h2>

              <Form.Item
                name={COMPANY.name.name}
                label={COMPANY.name.label}
                rules={COMPANY.name.rules}
                initialValue={props.company.name}
              >
                <Input
                  placeholder={COMPANY.name.label}
                  disabled={isPending}
                />
              </Form.Item>

              <Form.Item
                name={COMPANY.email.name}
                label={COMPANY.email.label}
                rules={COMPANY.email.rules}
                initialValue={props.company.email}
              >
                <Input
                  placeholder={COMPANY.email.label}
                  disabled={isPending}
                />
              </Form.Item>

              <Form.Item
                name={COMPANY.location.name}
                label={COMPANY.location.label}
                rules={COMPANY.location.rules}
                initialValue={props.company.country}
              >
                <Select
                  placeholder={COMPANY.location.label}
                  options={COMPANY.location.data.map((item) => ({
                    key: `${COMPANY.location.name}-${item}`,
                    value: item,
                  }))}
                  showSearch={true}
                  disabled={isPending}
                />
              </Form.Item>

              <Form.Item
                name={COMPANY.type.name}
                label={COMPANY.type.label}
                rules={COMPANY.type.rules}
                initialValue={props.company.company_type}
              >
                <Select
                  placeholder={COMPANY.type.label}
                  options={COMPANY.type.data.map((item) => ({
                    key: `${COMPANY.type.name}-${item}`,
                    value: item,
                  }))}
                  disabled={isPending}
                />
              </Form.Item>

              <Form.Item
                name={COMPANY.bio.name}
                label={COMPANY.bio.label}
                rules={COMPANY.bio.rules}
                initialValue={props.company.description}
              >
                <Input.TextArea
                  rows={4}
                  maxLength={240}
                  showCount={{ formatter: characterCountFormatter }}
                  placeholder={COMPANY.bio.placeholder}
                  disabled={isPending}
                />
              </Form.Item>

              <Form.Item
                name={COMPANY.expertise.name}
                label={(<span>{COMPANY.expertise.label}</span>)}
                rules={COMPANY.expertise.rules}
                initialValue={props.company.expertise}
              >
                <Select
                  mode="multiple"
                  placeholder={COMPANY.expertise.label}
                  options={COMPANY.expertise.data.map((item) => ({
                    key: `${COMPANY.expertise.name}-${item}`,
                    value: item,
                  }))}
                  showArrow={true}
                  showSearch={true}
                  disabled={isPending}
                />
              </Form.Item>

              <MultiSelectCustomInput
                form={form}
                isPending={isPending}
                attribute={props.company.focus || []}
                config={COMPANY.focus}
                isAdmin={false}
              />

              <MultiSelectCustomInput
                form={form}
                isPending={isPending}
                attribute={props.company.traffic || []}
                config={COMPANY.traffic}
                isAdmin={false}
              />

              <MultiSelectCustomInput
                form={form}
                isPending={isPending}
                attribute={props.company.geoTraffic || []}
                config={COMPANY.geoTraffic}
                isAdmin={false}
              />

              <Form.Item
                name={COMPANY.website.name}
                label={COMPANY.website.label}
                rules={COMPANY.website.rules}
                initialValue={props.company.url}
              >
                <Input
                  placeholder={COMPANY.website.placeholder}
                  disabled={isPending}
                />
              </Form.Item>

              <Form.Item
                name={COMPANY.facebook.name}
                label={COMPANY.facebook.label}
                rules={COMPANY.facebook.rules}
                validateFirst={true}
                initialValue={props.user.twitter}
              >
                <Input placeholder={COMPANY.facebook.placeholder} disabled={isPending} />
              </Form.Item>

              <Form.Item
                name={COMPANY.twitter.name}
                label={COMPANY.twitter.label}
                rules={COMPANY.twitter.rules}
                validateFirst={true}
                initialValue={props.user.facebook}
              >
                <Input placeholder={COMPANY.twitter.placeholder} disabled={isPending} />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} lg={10}>
            <Card style={{ marginBottom: "20px" }}>
              {props.company.is_booth_cached && (
                <>
                  <PageHeader
                    title={<h2>Create Booth</h2>}
                    extra={[
                      <Button
                        key="update-company-form-preview-booth"
                        icon={<Show set="bold" style={{ verticalAlign: "middle", marginRight: "10px" }} />}
                        shape="round"
                        onClick={() => {
                          setPreview({ ...preview, ...form.getFieldsValue(), main_color: background });
                          previewModal.open();
                        }}
                      >
                        Preview Booth
                      </Button>,
                    ]}
                    style={{ padding: "0px", marginBottom: "20px" }}
                  />

                  <Modal
                    visible={previewModal.isVisible}
                    modalRender={() => showPreview}
                    style={{ maxWidth: "420px" }}
                    onCancel={previewModal.shut}
                  />
                </>
              )}

              <Card style={{ backgroundImage: `linear-gradient(#FFFFFF -450%, ${background})`, backgroundColor: background, borderRadius: "10px" }}>
                {props.company.is_booth_cached && (
                  <div>
                    {props.company.ticket_type === TicketTypes.CompanySuperBooth && (
                      <img
                        src={companyHeaderBackground}
                        alt=""
                        style={{ position: "absolute", top: "0px", left: "0px" }}
                      />
                    )}

                    <Dropdown
                      arrow={true}
                      visible={isColorPickerShown}
                      trigger={[]}
                      placement="bottomCenter"
                      overlay={(
                        <Card style={{ padding: "0px" }}>
                          <Form.Item name="main_color" style={{ margin: "0px" }}>
                            <SketchPicker
                              color={background}
                              onChange={handleChange}
                              onChangeComplete={handleChangeComplete}
                              disableAlpha={true}
                              presetColors={[]}
                            />
                          </Form.Item>

                          <Button
                            type="primary"
                            block={true}
                            style={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px", marginTop: "-1px" }}
                            onClick={() => setIsColorPickerShown(false)}
                          >
                            Save
                          </Button>
                        </Card>
                      )}
                    >
                      <Button
                        icon={(
                          <Icons.WaterDrop
                            style={{
                              borderWidth: 0,
                              cursor: "pointer",
                              verticalAlign: "middle",
                              marginRight: "10px",
                            }}
                            size={24}
                            primaryColor={accent}
                          />
                        )}
                        type="link"
                        shape="round"
                        onClick={() => { setIsColorPickerShown((prev) => !prev); }}
                        style={{ float: "right", marginBottom: "20px", background, color: accent, border: `1px solid ${accent}` }}
                        disabled={isPending}
                      >
                        Change Color
                      </Button>
                    </Dropdown>

                    <div style={{ clear: "both" }} />
                  </div>
                )}

                <List bordered={true} className="form-list" style={{ borderRadius: "10px", borderColor: accent }}>
                  <List.Item>
                    <ImageUploadCompany
                      company={props.company}
                      isPending={isPending}
                      accent={accent}
                    />
                  </List.Item>
                </List>
              </Card>
            </Card>
          </Col>
        </Row>
      </Layout>
    </Form>
  );
};

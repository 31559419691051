import { CommonServiceDeps, PaginatedResponse, PaginationDTO } from "app/common";
import { ReactTypes } from "../talk";
import { PostEntity } from "./post.entity";
import { postStore } from "./post.store";

export interface GetPostsDTO extends PaginationDTO {
  cursor?: string | null;
  community?: string;
  identifier?: "community" | "lobby" | "admin";
}

interface UploadPhotoDTO {
  photo: string | Blob;
}

export interface ToggleReactionResponse {
  type: ReactTypes;
  action: "add" | "remove" | "change";
}

export type Post = Pick<PostEntity, "pinned" | "body" | "vimeo_id" | "schedule_at">;

export interface IPostService {
  getAll: (data: GetPostsDTO) => Promise<PaginatedResponse<PostEntity>>;
  getOne: (id: string) => Promise<PostEntity>;
  create: (data: Post) => Promise<PostEntity>;
  update: (data: Post, id: string) => Promise<PostEntity>;
  delete: (id: string) => Promise<void>;
  toggleReaction: (id: string, type: ReactTypes) => Promise<unknown>;
  removeReaction: (postId: string) => Promise<unknown>;
  uploadPhoto: (data: UploadPhotoDTO) => Promise<{ id: number, picture_url: string }>;
  deletePhoto: (data: UploadPhotoDTO) => Promise<void>;
}

export const postService = (props: CommonServiceDeps): IPostService => {
  return {
    getAll: ({ page, limit, cursor, community, identifier }: GetPostsDTO) => {
      return props.apiService
        .get("/posts", {
          params: {
            page,
            count: limit,
            community,
            startCursor: cursor,
          },
        })
        .then((response: PaginatedResponse<PostEntity>) => {
          props.dispatch(postStore.actions.addPosts({ resources: response.data, identifier }));
          return response;
        });
    },
    getOne: (id: string) => {
      return props.apiService
        .get<PostEntity>(`/posts/${id}`)
        .then((response: PostEntity) => {
          props.dispatch(postStore.actions.addPosts({ resources: [response] }));
          return response;
        });
    },
    create: (data: Post) => {
      return props.apiService
        .post<PostEntity>("/posts", data)
        .then((response) => {
          props.dispatch(postStore.actions.addPosts({ resources: [response], identifier: "lobby" }));
          return response;
        });
    },
    update: (data: Post, id: string) => {
      return props.apiService
        .patch<PostEntity>(`/posts/${id}`, data)
        .then((response: PostEntity) => {
          props.dispatch(postStore.actions.addPosts({ resources: [response] }));
          return response;
        });
    },
    delete: (id: string) => {
      return props.apiService
        .delete(`/posts/${id}`)
        .then((response) => {
          props.dispatch(postStore.actions.deletePost({ postId: id }));
          return response;
        });
    },
    toggleReaction: (id: string, type: ReactTypes) => {
      return props.apiService
        .post(`/posts/${id}/reactions`, { type })
        .then((response) => {
          props.dispatch(postStore.actions.toggleReaction({ postId: id, type }));
          return response;
        });
    },
    removeReaction: (id: string) => {
      return props.apiService
        .delete(`/posts/${id}/reactions`)
        .then((response) => {
          props.dispatch(postStore.actions.removeReaction({ postId: id }));
          return response;
        });
    },
    uploadPhoto: (data: UploadPhotoDTO): Promise<{ id: number, picture_url: string }> => {
      const formData = new FormData();
      formData.append("picture", data.photo);

      return props.apiService
        .post("/posts/photos/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    },
    deletePhoto: (data: UploadPhotoDTO) => {
      return props.apiService.delete("/posts/photos/upload", { data: { picture_url: data.photo } });
    },
  };
};

import { CommonServiceDeps } from "app/common";
import { scheduleStore } from "app/infra/schedule";
import { authStore } from "./auth.store";
import { companyStore } from "app/infra/company";
import { highlightStore } from "app/infra/highlights";
import { perkStore } from "app/infra/perk";
import { postStore } from "app/infra/posts";
import { raffleStore } from "app/infra/raffle";
import { userStore } from "app/infra/user";
import { AppDispatch } from "services";
import { sponsorshipStore } from "../sponsorships";

export const logout = ({ dispatch }: { dispatch: AppDispatch }) => {
  dispatch(scheduleStore.actions.reset());
  dispatch(authStore.actions.logout());
  dispatch(authStore.actions.reset());
  dispatch(companyStore.actions.reset());
  dispatch(highlightStore.actions.reset());
  dispatch(perkStore.actions.reset());
  dispatch(postStore.actions.reset());
  dispatch(raffleStore.actions.reset());
  dispatch(sponsorshipStore.actions.reset());
  dispatch(userStore.actions.reset());
};

export const authService = ({ apiService, dispatch }: CommonServiceDeps) => ({
  forgotPasswordForEmail: (email: string) =>
    apiService.post("attendee/forgot-password", {
      email,
    }),
  getMagicLink: (email: string) => {
    return apiService.post("users/magic-links", { email });
  },
  getJWTFromMagicLink: (token: string) => {
    return apiService
      .post<{ token: string }>("users/magic-links/verify", { token })
      .then((response) => {
        dispatch(authStore.actions.setToken(response.token));
      });
  },
  getJWTFromCredentials: (data: { email: string; password: string }) =>
    apiService
      .post<{ token: string }>("attendee/login", data)
      .then((response) => {
        dispatch(authStore.actions.setToken(response!.token));
      }),
  resetPassword: (data: { id: string; token: string; password: string }) =>
    apiService.post("attendee/change-password-forgot", data),
  // TODO: Find a way to clear the store with only one function.
});

import React, { ReactElement } from "react";
import BasicProps from "../BasicProps";

const SearchIcon = (props: BasicProps): ReactElement => {
  return (
    <svg
      className={`adw-search-icon ${props.className || ""}`}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      style={{
        position: props.position,
        backgroundColor: props.secondaryColor,
        borderColor: props.secondaryColor,
        width: props.size,
        height: props.size,
        fill: "none",
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
    >
      <circle
        cx="9.76663"
        cy="9.7666"
        r="8.98856"
        stroke={props.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0183 16.4851L19.5423 20"
        stroke={props.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};

SearchIcon.defaultProps = {
  position: "relative",
  primaryColor: "#A3A7B2",
  secondaryColor: "transparent",
  size: 20,
  style: undefined,
};

export default SearchIcon;

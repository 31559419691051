import { Form, Select, Input } from "antd";
import { FormInstance } from "antd/lib/form";
import { SelectValue } from "antd/lib/select";
import { ConstantConfig } from "config/constants/user";
import _ from "lodash";
import React, { BaseSyntheticEvent, useState } from "react";

interface Props {
  form: FormInstance;
  attribute: string[];
  isPending: boolean;
  config: ConstantConfig;
  isAdmin: boolean;
}

export const MultiSelectCustomInput = (props: Props) => {
  const [allAttributes, setAllAttributes] = useState(_.union(props.config.data, props.attribute));
  const [isOtherSelected, setisOtherSelected] = useState(
    props.attribute.length === 1 && props.attribute[0] === "Other",
  );

  const onChange = (value: string[]) => {
    props.form.setFieldsValue({
      [props.config.name]: value,
    });
  };

  const onSelect = (value: SelectValue) => {
    if (value.toString() === "Other") {
      props.form.setFieldsValue({ [`sub${props.config.name}`]: "" });
      setisOtherSelected(true);
      const input = props.form.getFieldInstance(`sub${props.config.name}`).input as HTMLInputElement;
      setTimeout(() => { input.focus(); }, 250);
    } else {
      setisOtherSelected(false);
    }
    const current = props.form.getFieldValue(props.config.name) as string[];
    onChange(_.union(current, [value.toString()]));
  };

  const onPressEnter = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    if (event.target.value) {
      setAllAttributes(_.union(allAttributes, [event.target.value]));
      const current = (props.form.getFieldValue(props.config.name) as string[]).filter((a) => a !== "Other");
      props.form.setFieldsValue({ [props.config.name]: _.union(current, [event.target.value]) });
    }
    setisOtherSelected(false);
  };

  return (
    <>
      <Form.Item
        name={props.config.name}
        label={props.config.label}
        rules={props.isAdmin ? [] : props.config.rules || []}
        initialValue={props.attribute}
      >
        <Select
          mode="multiple"
          showSearch={true}
          disabled={props.isPending}
          options={allAttributes.map((item) => ({ value: item }))}
          onSelect={onSelect}
          onChange={onChange}
        />
      </Form.Item>
      <Form.Item
        name={`sub${props.config.name}`}
        style={{ display: isOtherSelected ? "block" : "none" }}
      >
        <Input
          disabled={props.isPending}
          onPressEnter={onPressEnter}
        />
      </Form.Item>
    </>
  );
};

import { Rule } from "antd/lib/form";

export type ConstantConfig = {
  name: string;
  label: string;
  placeholder: string;
  data: string[];
  rules: Rule[];
};

export const USER = Object.freeze({
  firstName: {
    name: "first_name",
    label: "First Name",
    rules: [
      {
        required: true,
        message: "First Name is required",
      },
      {
        min: 2,
        max: 20,
        message: "First Name must be within 2 - 20 characters",
      },
    ],
  } as ConstantConfig,

  lastName: {
    name: "last_name",
    label: "Last Name",
    rules: [
      {
        required: true,
        message: "Last Name is required",
      },
      {
        min: 2,
        max: 20,
        message: "Last Name must be within 2 - 20 characters",
      },
    ],
  } as ConstantConfig,

  gender: {
    name: "gender",
    label: "Gender",
    data: ["Male", "Female", "Other"],
  } as ConstantConfig,

  picture: {
    name: "profile_picture_url",
    rules: [{
      required: true,
      message: "Profile picture is required",
    }],
  } as ConstantConfig,

  focus: {
    name: "focus",
    label: "Choose ONE to FOUR niches/verticals that you focus on?",
    data: [
      "Lead Gen Travel",
      "Business Opportunity",
      "Gaming",
      "Mobile App",
      "Content Arbitrage",
      "Health & Beauty",
      "Mobile Content",
      "Content Marketing",
      "Lead Gen Education",
      "Nutra",
      "Crypto",
      "Lead Gen Finance",
      "Pay Per Call",
      "Dating",
      "Lead Gen Insurance",
      "Smartlinks",
      "Downloadable Software",
      "Lead Gen Legal",
      "Ecom Store Owner",
      "Lead Gen Solar",
      "Streaming",
      "Ecommerce (Affiliate, Offers)",
      "Other",
    ],
    rules: [
      {
        required: true,
        type: "array",
        min: 1,
        message: "You must select at least 1 focus niche",
      },
      {
        type: "array",
        max: 4,
        message: "You cannot select more than 4 foucus niches",
      },
    ],
  } as ConstantConfig,

  traffic: {
    name: "traffic",
    label: "What type of traffic are you interested in?",
    data: [
      "Display (Desktop)",
      "Paid Search",
      "TikTok",
      "Display (Mobile)",
      "Pinterest",
      "Video",
      "Email",
      "Pops & Redirect",
      "WhatsApp",
      "Facebook",
      "Push",
      "Worldwid",
      "Instagra",
      "SEO",
      "YouTube",
      "LinkedIn",
      "SMS",
      "None",
      "Native",
      "SnapChat",
      "Other",
    ],
  } as ConstantConfig,

  monthly_revenue: {
    name: "monthly_revenue",
    label: "How much monthly revenue do you generate?",
    data: [
      "$0 - $1k",
      "$1k - $5k",
      "$5k - $10k",
      "$10k - $50k",
      "$50k - $100k",
      "$100k+",
      "Other",
    ],
  } as ConstantConfig,

  geoTraffic: {
    name: "geoTraffic",
    label: "What geos do you run traffic in?",
    data: [
      "US",
      "CA",
      "EU",
      "Asia",
      "Other",
    ],
  } as ConstantConfig,

  hasAgency: {
    name: "hasAgency",
    label: "Are you part of, or own an Agency?",
  } as ConstantConfig,

  stmUsername: {
    name: "stmUsername",
    label: "STM Username",
  } as ConstantConfig,

  website: {
    name: "website",
    label: "Website",
    placeholder: "Website URL e.g: https://example.com",
    rules: [
      {
        type: "url",
        message: "Not a valid URL",
      },
    ],
  } as ConstantConfig,

  company: {
    logo: {
      name: "companyLogo",
      rules: [{
        required: true,
        message: "Company Logo is required",
      }],
    } as ConstantConfig,

    type: {
      name: "attendee_company_type",
      label: "Company Type",
      rules: [{
        required: true,
        message: "Company Type is required",
      }],
      data: [
        "Creator",
        "Brand",
        "Service Provider",
        "Digital Marketer",
        "Entrepreneur",
        "Freelancer",
        "Agency",
        "Investor",
        "Other",
      ],
    } as ConstantConfig,

    name: {
      name: "attendee_company",
      label: "Company Name",
      rules: [
        {
          required: true,
          message: "Company Name is required",
        },
        {
          min: 2,
          max: 50,
          message: "Company Name must be within 2 - 50 characters",
        },
      ],
    } as ConstantConfig,

    description: {
      name: "companyDescription",
      label: "Company Description",
      placeholder: "Write a short company description (max 250 characters)",
      rules: [
        {
          required: true,
          message: "Company Description is required",
        },
        {
          max: 250,
          message: "Bio cannot be longer than 250 characters",
        },
      ],
    } as ConstantConfig,
  },
});

import { CommonServiceDeps } from "app/common";
import { ConnectionEntity } from "app/infra/user";
import { connectionStore } from "./connection.store";

export interface IConnectionService {
  create: (id: string) => Promise<ConnectionEntity>;
}

export const ConnectionService = ({
  apiService,
  dispatch,
}: CommonServiceDeps) => ({
  create: (id: string) =>
    apiService
      .post<ConnectionEntity>(`/connections/${id}`)
      .then((response) => {
        dispatch(connectionStore.actions.addResources({ resources: [response]}))
        return response;
      }),
});

import React from "react";
import { Link } from "react-router-dom";
import { findMatch, UserEntity } from "app/infra/user";
import { Tag } from "antd";
import { mode, AppModes } from "app/infra/app";
import { connect } from "react-redux";
import { RootState } from "services";
import AttendeesFullName from "components/AttendeesFullName/AttendeesFullName";

import { UserAvatar } from "components/User/UserAvatar";
import { ChevronRightCircle } from "react-iconly";

interface PersonItemProps {
  user: UserEntity;
  me?: UserEntity;
  isAdmin: boolean;
}

export const PersonItem = connect((state: RootState) => ({
  isAdmin: state.authStore.isAdmin,
}))(({ me, user, isAdmin }: PersonItemProps) => {
  const foundMatches = findMatch(me?.traffic || [], user?.traffic || []);
  const { first_name, last_name } = user;

  return (
    (user && (
      <Link
        to={`/app/user-profile/${user.id}`}
        className="person-item"
        onClick={(e) => {
          if (mode === AppModes.onboarding && !isAdmin) {
            e.preventDefault();
          }
        }}
      >
        <div className="person-item-container">
          <div>
            <UserAvatar user={user} size={60} style={{ marginRight: "15px" }} />
          </div>

          <div className="person-item-info">
            <AttendeesFullName
              firstName={first_name}
              lastName={last_name}
            />
            <span className="position">
              {/* {user.job_position} at{" "} */}
              {user.company?.name || user.attendee_company || ""}
            </span>
          </div>

          <ChevronRightCircle set="bold" style={{ alignSelf: "center", marginLeft: "auto" }} />
        </div>

        <div style={{ marginTop: "10px" }}>
          {foundMatches && foundMatches.length > 0 && (
            <div className="subtitle">
              <span className="text">Common traffic interest: </span>
              {foundMatches.map((match: string | false, index: number) => (
                <Tag
                  key={index}
                  className="ant-tag-small"
                >
                  {match}
                </Tag>
              ))}
            </div>
          )}
        </div>
      </Link>
    )) || <div />
  );
});

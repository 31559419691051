import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const RefreshIcon = (props: BasicProps): ReactElement => {
  return (
    <svg
      className={`adw-refresh-icon ${props.className || ""}`}
      style={{
        width: props.size,
        height: props.size,
        position: props.position,
        backgroundColor: props.secondaryColor,
        fill: props.primaryColor,
        borderStyle: "solid",
        borderColor: props.secondaryColor,
        borderRadius: props.borderRadius,
        borderWidth: props.borderWidth,
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M16.537 17.567C14.7224 19.1393 12.401 20.0033 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10
        0C15.523 0 20 4.477 20 10C20 12.136 19.33 14.116 18.19 15.74L15 10H18C17.9998 8.15621 17.3628
        6.36906 16.1967 4.94089C15.0305 3.51272 13.4069 2.53119 11.6003 2.16236C9.79381 1.79352 7.91533
        2.06002 6.28268 2.91677C4.65002 3.77351 3.36342 5.16791 2.64052 6.86408C1.91762 8.56025 1.80281
        10.4541 2.31549 12.2251C2.82818 13.9962 3.93689 15.5358 5.45408 16.5836C6.97127 17.6313 8.80379
        18.1228 10.6416 17.9749C12.4795 17.827 14.2099 17.0488 15.54 15.772L16.537 17.567Z"
      />
    </svg>
  );
};

RefreshIcon.defaultProps = {
  size: 40,
  weight: "5",
  position: "relative",
  primaryColor: "#4E5964",
  secondaryColor: "transparent",
  borderRadius: "50%",
  borderWidth: "10px",
};

export default RefreshIcon;

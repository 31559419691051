import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const TickRound = (props: BasicProps): ReactElement => {
  return (
    <svg
      style={{
        width: props.size,
        height: props.size,
        position: props.position,
        fill: props.primaryColor,
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      viewBox="0 0 20 20"
    >
      <path d="M10,20h0A10,10,0,0,0,20,10h0A10,10,0,0,0,10,0h0A10,10,0,0,0,0,10H0A10,
      10,0,0,0,10,20ZM14.18,8.24,9.43,13A.89.89,0,0,1,8.2,13L5.82,10.62A.86.86,0,0,1,
      5.56,10a.88.88,0,0,1,.88-.87.9.9,0,0,1,.62.26l1.75,1.75L12.94,7a.86.86,0,0,1,1.21,0A.87.87,0,0,1,14.18,8.24Z" />
    </svg>
  );
};

TickRound.defaultProps = {
  size: 40,
  primaryColor: "#fff",
};

export default TickRound;

import React from "react";
import { SponsorshipEntity } from "app/infra/sponsorships";

interface SponsorActionProps {
  sponsorship?: SponsorshipEntity;
}

export const SponsorAction = ({ sponsorship }: SponsorActionProps) =>
  (sponsorship && (
    <div
      className="forum-action"
      style={{
        marginTop: -5,
      }}
    >
      <span>POWERED BY</span>

      <a href={sponsorship.link} target="_blank" rel="noopener noreferrer">
        <img src={sponsorship.imageURL} height={70} alt={sponsorship.title} />
      </a>
    </div>
  )) || <div />;

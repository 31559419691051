import React, { useMemo, useState, useContext } from "react";
import { Link } from "react-router-dom";

import Countdown, { CountdownTimeDelta, zeroPad } from "react-countdown";

import { useOnMount } from "hooks/useOnMount";
import { useOnError } from "hooks/useOnError";
import { addMinutes, compareDesc, format, isBefore } from "date-fns";

import { DiContext, useAsync } from "app/common/utils";
import { scheduleService } from "app/infra/schedule";

import { StageEntity } from "app/infra/stage";
import { TalkEntityExtended } from "app/infra/talk";

import { take } from "lodash";
import { Calendar, ChevronRight, Play } from "react-iconly";
import { UserAvatar } from "components/User/UserAvatar";
import { Loading } from "components/Loading/Loading";
import { Tag, Tooltip } from "antd";

interface StageProps {
  stage: StageEntity<TalkEntityExtended>;
  currentTalks: string[];
  myTalks: string[];
}

export const Stage = (props: StageProps) => {
  const [time, setTime] = useState(Date.now());

  useOnMount(() => {
    const interval = setTimeout(() => {
      setTime(Date.now());
    }, 60000);

    return () => {
      clearTimeout(interval);
    };
  });

  const currentTalk = useMemo(() => {
    return props.stage.talks.find((talk) =>  props.currentTalks.includes(talk.id));
  }, [props.stage.talks, props.currentTalks]);

  const upcomingTalks = useMemo(() => {
    return props.stage.talks.filter((talk) => isBefore(time, Date.parse(talk.start_time)));
  }, [props.stage.talks, time]);

  const previousTalks = useMemo(() => {
    return props.stage.talks
      .filter((talk) =>
        isBefore(addMinutes(Date.parse(talk.start_time), talk.length), time))
      .sort((a, b) =>
        compareDesc(Date.parse(a.start_time), Date.parse(b.start_time)));
  }, [props.stage.talks, time]);

  interface TalkProps {
    talk: TalkEntityExtended;
  }

  const countDownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
  }: CountdownTimeDelta) => {
    if (days > 0)
      return `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(
        seconds
      )}`;
    if (hours > 0)
      return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;

    return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
  };

  const Talk = ({ talk }: TalkProps) => {
    const { apiService, dispatch } = useContext(DiContext);
    const scheduleSrv = scheduleService({ apiService, dispatch });

    const { pending, execute, error } = useAsync(
      (add: boolean) =>
        add
          ? scheduleSrv.addTalkToMySchedule(talk.id)
          : scheduleSrv.removeTalkFromMySchedule(talk.id),
      false,
    );

    useOnError(error);

    const isStageBreak = (event: TalkEntityExtended) => {
      return event.speakers?.map((speaker) => `${speaker.first_name} ${speaker.last_name}`).join(", ").toLocaleLowerCase().includes("stage break");
    };

    const isStageClosed = (event: TalkEntityExtended) => {
      return event.title.toLowerCase().includes("stage closed");
    };

    return (
      <div className="stage-talk-item">
        <Link
          to={`/app/talk/${talk.id}`}
          className="talk-wrapper"
          style={{ textDecoration: "none" }}
        >
          <div className="talk-wrapper-content">
            <div style={{ marginRight: 10 }}>
              {talk.speakers && (
                <UserAvatar user={talk.speakers[0]} size={70} />
              )}
            </div>

            <div className="talk-wrapper-content-info">
              <div className="talk-wrapper-time">
                <span style={{ marginRight: "10px", color: "#A3A7B2" }}>
                  {format(Date.parse(talk.start_time), "kk:mm")}
                </span>

                {talk.speakers && (
                  <h6
                    style={{ color: "#4B4C53", verticalAlign: "middle", marginBottom: "-2px" }}
                  >
                    {talk.speakers[0].first_name} {talk.speakers[0].last_name}
                  </h6>
                )}
              </div>

              <span
                className="upcoming-talk-title"
                style={{ display: "block" }}
              >
                {talk.title}
              </span>

              <Tag
                style={{
                  marginTop: "10px",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  display: "inline-block",
                }}
                className="ant-tag-small"
              >
                {talk.track?.name}
              </Tag>
            </div>
          </div>
        </Link>

        {!isStageBreak(talk) && !isStageClosed(talk) && (
          <div
            role="none"
            onClick={() => {
              !pending && execute(!props.myTalks.includes(talk.id));
            }}
            className={`notification-wrapper ${(props.myTalks.includes(talk.id) &&
              "active-notification") ||
              ""}`}
          >
            {(pending && <Loading />) || (
              <Tooltip
                title={props.myTalks.includes(talk.id) ? "Remove from schedule" : "Add to schedule"}
              >
                {" "}
                <Calendar set="bold" />{" "}
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
    <div className="stage-section-wrapper">
        <div className="stage-section">
          <h2 className={!currentTalk ? "closed" : ""}>
            {props.stage.name} {!currentTalk && " (CLOSED)"}
          </h2>

          {currentTalk ? (
            <Link
              className="current-talk opened"
              to={`/app/talk/${currentTalk.id}`}
            >
              <div
                className="current-talk-preview"
                style={{
                  backgroundImage: `url(${currentTalk.cover_url || ""})`,
                }}
              >
                <div className="blur-filter" />

                <Tag
                  style={{
                    color: "white",
                    background: "#FF3D57",
                    display: "flex",
                    position: "absolute",
                    top: "21px",
                    left: "16px",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  <svg
                    viewBox="0 0 100 100"
                    height="15"
                    width="15"
                    style={{ marginRight: "10px" }}
                  >
                    <circle cx="50" cy="50" r="40" fill="white" />
                  </svg>
                  Streaming
                </Tag>

                <div className="play-button-stream">
                  <Play set="bold" />
                </div>
              </div>
            </Link>
          ) : (
            <div className="current-talk closed">
              {upcomingTalks.length > 0 && (
                <div className="opens-in">
                  <span>Stage Closed. Opens in:</span>
                  <span>
                    <Countdown
                      date={Date.parse(upcomingTalks[0].start_time)}
                      renderer={countDownRenderer}
                      intervalDelay={0}
                    />
                  </span>
                </div>
              )}
            </div>
          )}

          <div className="stage-content-wrapper">
            <div className="current-talk-info-wrapper">
              {currentTalk ? (
                <div className="current-talk-info">
                  <div className="current-talk-info-time">
                    {currentTalk &&
                      format(Date.parse(currentTalk.start_time), "kk:mm")}
                  </div>

                  <Link
                    to={`/app/talk/${currentTalk.id}`}
                    title={currentTalk.title}
                    style={{ display: "block" }}
                  >
                    {currentTalk.title}
                  </Link>

                  <Tag className="ant-tag-small" style={{ marginTop: "17px" }}>
                    {currentTalk.track?.name}
                  </Tag>
                </div>
              ) : (
                <span className="no-talks-streaming">
                  There is no talk streaming now.
                </span>
              )}
            </div>

            <div className="next-stage-container">
              <section>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "#4B4C53",
                  }}
                >
                  <Tag
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "10px",
                      padding: "0px",
                    }}
                    className="stage-up-next-icon"
                  >
                    <ChevronRight set="bold" />
                    <ChevronRight set="bold" style={{ marginLeft: "-35%" }} />
                  </Tag>
                  Up Next
                </div>

                {upcomingTalks.length > 0 ? (
                  <div className="talks-wrapper">
                    <div className="talks-wrapper-container">
                      {take(upcomingTalks, 4).map((talk) => (
                        <Talk key={`upcoming-talk-${talk.id}`} talk={talk} />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="no-upcoming-talks">
                    There are no upcoming talks.
                  </div>
                )}
              </section>

              <div className="view-more">
                <Link to="/app/schedule?type=Schedule">
                  <Calendar set="bold" />
                  <span>View Schedule</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-addon"></div>
    </>
  );
};

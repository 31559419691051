import { CommonServiceDeps } from "app/common";
import { SponsorshipEntity } from "./SponsorshipEntity";
import { sponsorshipStore } from "./SponsorshipStore";

interface GetSponsorshipsDTO {
  identifier?: string;
}

interface UpdateSponsorshipPhotoDTO {
  id: string;
  photo: string | Blob;
}

export type Sponsorship = Pick<SponsorshipEntity, "title" | "description" | "link">;

export interface ISponsorshipService {
  getAll: (data: GetSponsorshipsDTO) => Promise<SponsorshipEntity[]>;
  create: (data: Sponsorship) => Promise<SponsorshipEntity>;
  update: (data: Sponsorship, id: string) => Promise<SponsorshipEntity>;
  delete: (id: string) => Promise<void>;
  updatePhoto: (data: UpdateSponsorshipPhotoDTO) => Promise<void>;
}

export const sponsorshipService = (props: CommonServiceDeps): ISponsorshipService => {
  return {
    getAll: ({ identifier }: GetSponsorshipsDTO) => {
      return props.apiService
        .get<SponsorshipEntity[]>("/sponsorships")
        .then((response: SponsorshipEntity[]) => {
          props.dispatch(sponsorshipStore.actions.addSponsorships({ resource: response }));

          if (identifier) {
            props.dispatch(sponsorshipStore.actions.addOrUpdateKeyedIds({
              key: identifier,
              ids: response.map((sponsorship) => sponsorship.id),
            }));
          }

          return response;
        });
    },
    create: (data: Sponsorship) => {
      return props.apiService
        .post<SponsorshipEntity>("sponsorships", data)
        .then((response: SponsorshipEntity) => {
          props.dispatch(sponsorshipStore.actions.addSponsorships({ resource: [response] }));
          return response;
        });
    },
    update: (data: Sponsorship, id: string) => {
      return props.apiService
        .patch<SponsorshipEntity>(`sponsorships/${id}`, data)
        .then((response: SponsorshipEntity) => {
          props.dispatch(sponsorshipStore.actions.addSponsorships({ resource: [response] }));
          return response;
        });
    },
    delete: (id: string) => {
      return props.apiService
        .delete(`sponsorships/${id}`)
        .then(() => {
          props.dispatch(sponsorshipStore.actions.deleteSponsorship({ sponsorshipId: id }));
        });
    },
    updatePhoto: (data: UpdateSponsorshipPhotoDTO) => {
      const formData = new FormData();
      formData.append("picture", data.photo);
      return props.apiService
        .post(`sponsorships/image/${data.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    },
  };
};

import { createSlice } from "@reduxjs/toolkit";

interface ChatStore {
  hasConsented: boolean;
  currentRoomId: string;
  unreadMessages: number;
}

export const chatStore = createSlice({
  name: "chatStore",
  initialState: {
    hasConsented: false,
    currentRoomId: "",
    unreadMessages: 0,
  } as ChatStore,
  reducers: {
    update: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

// Taken from font-awesome source code
const CompressIcon = (props: BasicProps): ReactElement => {
  return (
    <svg
      className={`adw-compress-icon ${props.className || ""}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={{
        width: props.size,
        height: props.size,
        fill: props.primaryColor,
        verticalAlign: "-0.125em",
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <path d="M436 192H312c-13.3 0-24-10.7-24-24V44c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v84h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-276-24V44c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v84H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24zm0 300V344c0-13.3-10.7-24-24-24H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-84h84c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12H312c-13.3 0-24 10.7-24 24v124c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12z" />
    </svg>
  );
};

CompressIcon.defaultProps = {
  size: 15,
  primaryColor: "currentColor",
};

export default CompressIcon;

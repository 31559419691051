import { CommonServiceDeps, PaginationDTO } from "app/common";
import { AxiosRequestConfig } from "axios";

import _ from "lodash";

import { userStore } from "./user.store";
import { UserEntity } from "./user.entity";
import { authStore } from "../auth";

interface GetCertificateQueryResponse {
  url: string;
}

interface GetUsersDTO extends PaginationDTO {
  search?: string;
  cursor?: string;
  connections?: boolean;
  role?: string;
  component?: string;
  interests?: string[];
  countries?: string[];
  isComplete?: boolean;
}

interface UpdateMeDTO {
  first_name?: string;
  last_name?: string;
  title?: string;
  bio?: string;
  company?: string;
  interested?: string[];
  attendee_company?: string;
  lookingForWork?: boolean;
  consented?: boolean;
}

interface UpdateProfilePhotoDto {
  photo: string | Blob;
}

export interface CreateUserDTO {
  reference: string;
  email: string;
  consented?: boolean;
}

interface UpdateNewPassword {
  oldPassword: string;
  newPassword: string;
}

export const userService = ({ dispatch, apiService }: CommonServiceDeps) => ({
  me: () =>
    apiService.get("profile").then((response) => {
      const user = { ...response, id: "me", old_id: response.id };
      dispatch(userStore.actions.updateUsers({ resources: [user] }));
      return user;
    }),

  getCertificate: () =>
    apiService.get<GetCertificateQueryResponse>("certificate/new"),

  create: (data: CreateUserDTO) =>
    apiService.post<{ token: string }>("attendee/create-password", data).then((response) => {
      dispatch(authStore.actions.setToken(response.token));
    }),

  updateMe: (data: UpdateMeDTO) =>
    apiService
      .patch("profile", { ...data, description: undefined })
      .then((response) => {
        const user = { ...response, id: "me" };
        dispatch(userStore.actions.updateUsers({ resources: [user] }));
        return user;
      }),

  deleteProfilePhoto: () =>
    apiService.delete("profile/picture").then((response) => {
      const user = { ...response, id: "me" } as UserEntity;
      dispatch(userStore.actions.updateUsers({ resources: [user] }));
      return response;
    }),

  updateNewPassword: (data: UpdateNewPassword) =>
    apiService.post("attendee/change-password", data),

  updateProfilePhoto: ({ photo }: UpdateProfilePhotoDto) => {
    const formData = new FormData();
    formData.append("picture", photo);
    return apiService
      .post("profile/picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        const user = { ...response, id: "me" } as UserEntity;
        dispatch(userStore.actions.updateUsers({ resources: [user] }));
        return response;
      });
  },

  get: (id: string) =>
    apiService.get<UserEntity>(`directory/${id}`).then((response) => {
      dispatch(userStore.actions.updateUsers({ resources: [response] }));
      return response;
    }),

  getAll: ({
    page,
    limit,
    search,
    interests,
    countries,
    cursor,
    role,
    component,
    isComplete,
  }: GetUsersDTO) =>
    apiService
      .get("directory", {
        params: {
          page,
          count: limit,
          search,
          interests,
          countries,
          startCursor: cursor,
          role,
          isComplete,
        },
      })
      .then((response) => {
        const users = response.data as UserEntity[];

        let ids = users.map((user: UserEntity) => user.id);
        const keyedstamp = `${component}-${limit}-${page}-${search}`;
        dispatch(userStore.actions.addUsers({ resources: users }));

        // TODO: Refactor this
        if (component) {
          if (component === "suggested-users") {
            ids = _.shuffle(ids);
          }
          dispatch(
            userStore.actions.addKeyedIds({
              keyedstamp,
              ids,
            }),
          );

          if (page === 0) {
            dispatch(
              userStore.actions.addKeyedIds({
                keyedstamp: component,
                ids: [keyedstamp],
              }),
            );
          } else {
            dispatch(
              userStore.actions.addOrUpdateKeyedIds({
                keyedstamp: component,
                ids: [keyedstamp],
              }),
            );
          }
        }

        return response;
      }),
  getProfileData: (config: AxiosRequestConfig) =>
    apiService.post<string | Blob>("/stats/profiles", {}, config),

  image: {
    update: (photo: string | Blob, id: string, field: string, isAdmin: boolean) => {
      const formData = new FormData();
      formData.append("picture", photo);
      formData.append("id", id);
      formData.append("field", field);

      return apiService
        .post<UserEntity>("users/images", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then<UserEntity>((response: UserEntity) => {
          const user = { ...response, id: isAdmin ? id : "me" } as UserEntity;
          dispatch(userStore.actions.updateUsers({ resources: [user] }));
          return user;
        });
    },

    delete: (id: string, field: string, isAdmin: boolean) => {
      return apiService
        .delete("users/images", { data: { id, field } })
        .then((response) => {
          const user = { ...response, id: isAdmin ? id : "me" } as UserEntity;
          dispatch(userStore.actions.updateUsers({ resources: [user] }));
          return user;
        });
    },
  },
});

import React, { useContext } from "react";

import { DiContext } from "app/common";

import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";

import { Loading } from "components/Loading/Loading";

import {
  Avatar,
  Button,
  notification,
  Tooltip,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";
import ImgCrop from "antd-img-crop";

import { Edit } from "react-iconly";
import { CompanyEntity, companyService } from "app/infra/company";

interface ImageUploadCompanyProps {
  isPending: boolean,
  company: CompanyEntity,
  accent: string,
}

// TO BE MERGED

export const ImageUploadCompany = (props: ImageUploadCompanyProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const {
    execute: upload,
    isPending: isUploadPending,
    error: uploadError,
  } = useAsync(({ file }: { file: string | Blob }) => {
    return companySrv.updateCompanyPhoto({ photo: file }).then((response) => {
      return response;
    });
  });

  useOnError(uploadError);

  const beforeUpload = (file: RcFile) => {
    if (file.size > 5000000) {
      notification.error({ message: "Photo must be less than 5mb" });
      return false;
    }

    return true;
  };

  return (
    <ImgCrop>
      <Upload
        name="avatar"
        accept=".png, .jpg, .jpeg"
        listType="picture-card"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={upload}
        className="ant-upload-picture-card-wrapper-circle"
        disabled={props.isPending || isUploadPending}
      >
        <span>
          <Avatar src={props.company.logo_url} style={{ zIndex: 0 }} />

          {isUploadPending && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "#FFFFFF",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                zIndex: 4,
              }}
            >
              <Loading
                style={{ marginTop: "5px" }}
                size={30}
              />
            </div>
          )}

          <Tooltip
            title={(
              <b>
                Upload Image (.png/.jpg/.jpeg) <br /> recommended size is 150x150
              </b>
            )}
            color={props.accent}
            placement="top"
            overlayInnerStyle={{ color: props.accent === "white" ? "black" : "white", textAlign: "center" }}
          >
            <Button
              size="small"
              shape="circle"
              style={{
                background: props.accent,
                width: "35px",
                height: "35px",
                zIndex: 5,
              }}
              disabled={props.isPending || isUploadPending}
            >
              <Edit set="bold" size={14} primaryColor={props.accent === "white" ? "black" : "white"} />
            </Button>
          </Tooltip>
        </span>
      </Upload>
    </ImgCrop>
  );
};

import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "qs";

import { connect } from "react-redux";
import { RootState } from "services";

import { useSessionState } from "hooks/useSessionState";

import { SegmentedControl } from "app/common";
import { TrackEntity } from "app/infra/track";

import { Button, Input } from "antd";
import { Calendar, Star, Search } from "react-iconly";
import Icons from "components/Icons";

import { StringBooleanMap } from "components/CheckboxFilter/CheckboxFilter";
import { Schedule } from "./schedule";
import ScheduleFilter from "./ScheduleFilter";

enum ListTypes {
  SCHEDULE = "Schedule",
  MY_SCHEDULE = "My Schedule",
}

interface SchedulePageQueryParams {
  type?: ListTypes;
}

const items = [
  {
    text: ListTypes.SCHEDULE,
    Icon: () => <Calendar set="bold" />,
  },
  {
    text: ListTypes.MY_SCHEDULE,
    Icon: () => <Star set="bold" />,
  },
];

interface SchedulePageProps {
  tracks: TrackEntity[];
  currentTalks: string[];
}

const mapStateToProps = (state: RootState): SchedulePageProps => {
  return {
    tracks: Object.values(state.trackStore.byId),
    currentTalks: state.scheduleStore.currentTalks,
  };
};

export const SchedulePage = connect(mapStateToProps)((props: SchedulePageProps) => {
  const history = useHistory();
  const location = useLocation();

  const [textFilter, setTextFilter] = useSessionState("schedule.page:search", "");
  const [selectedTracks, setSelectedTracks] = useSessionState<StringBooleanMap>("schedule.page:tracks", {});
  const [selectedFormats, setSelectedFormats] = useSessionState<StringBooleanMap>("schedule.page:formats", {});

  const formats = [{ id: "liveQA", name: "Live Q&A" }, { id: "liveMixer", name: "Networking Mixer" }];

  const tracks = useMemo(() => {
    return props.tracks
      .filter((track) => track.name !== "Break")
      .sort((a, b) => (a.name < b.name ? 1 : -1));
  }, [props.tracks]);

  const selectedTracksArray = useMemo(() => {
    return Object.keys(selectedTracks).filter((track) => selectedTracks[track]);
  }, [selectedTracks]);

  const selectedFormatsArray = useMemo(() => {
    return Object.keys(selectedFormats).filter((format) => selectedFormats[format]);
  }, [selectedFormats]);

  const showClearAllFilters = useMemo(() => {
    return textFilter.length > 0 || selectedTracksArray.length > 0 || selectedFormatsArray.length > 0;
  }, [textFilter, selectedTracksArray, selectedFormatsArray]);

  const clearFilters = () => {
    setTextFilter("");
    setSelectedTracks({});
    setSelectedFormats({});
  };

  const queryParams = parse(location.search, { ignoreQueryPrefix: true }) as SchedulePageQueryParams;
  const selectedList = queryParams.type || ListTypes.SCHEDULE;

  const setSelectedList = (type: string) => {
    history.push(`/app/schedule?type=${type}`);
  };

  useEffect(() => {
    if (!queryParams.type) {
      setSelectedList(ListTypes.SCHEDULE);
    }
  }, [queryParams]);

  return (
    <div className="agenda-page">
      <SegmentedControl
        selected={selectedList}
        items={items}
        setSelected={setSelectedList}
        divider={true}
      />

      <div className="wrapper">
        <div className="checkbox-filters">
          <div className="search-input">
            <Input
              className="adw-white-input"
              size="large"
              placeholder="Search"
              prefix={<Search set="light" primaryColor="#A3A7B2" size={20} />}
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
            />
            {textFilter && <Icons.Cancel onClick={() => setTextFilter("")} />}
          </div>

          <div className="filter-right">
            {showClearAllFilters && (
              <Button
                type="link"
                onClick={clearFilters}
                className="clear-filters-btn"
              >
                Clear Filters
              </Button>
            )}

            <ScheduleFilter
              tracks={tracks}
              selectedTracks={selectedTracks}
              setSelectedTracks={setSelectedTracks}
              formats={formats}
              selectedFormats={selectedFormats}
              setSelectedFormats={setSelectedFormats}
            />
          </div>
        </div>

        <Schedule
          currentMethod={selectedList.toString()}
          currentEvents={props.currentTalks}
          selectedTracksArray={selectedTracksArray}
          liveQA={selectedFormats["liveQA"]}
          liveMixer={selectedFormats["liveMixer"]}
          query={textFilter}
        />
      </div>
    </div>
  );
});

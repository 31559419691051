/* eslint-disable */
import axios, {AxiosResponse, AxiosInstance} from "axios";
import { UserEntity } from "app/infra/user";
import { app } from "config";
import crypto from "crypto";

const request: AxiosInstance = axios.create({ baseURL: app.gcUrl });

export enum EPubSubTable {
    LogIn =  "logins",
    Chat =  "chat",
    Video =  "video_events",
    PageView =  "page_views",
    Download =  "downloads",
    Perk =  "perks",
    Raffle =  "raffles",
}

type TPubSubDataBase = {
    user_id: string;
    datetime: string;
};

type TPubSubDataLogin = TPubSubDataBase & {
    ip: string;
    user_agent: string;
};

type TPubSubDataChat = TPubSubDataBase & {
    channel_id: string;
};

type TPubSubDataVideoEvent = TPubSubDataBase & {
    type: "live" | "replay" | "post-live";
    timestamp: number;
    platform: string;
    video_id: string;
};

type TPubSubDataPageView = TPubSubDataBase & {
    path: string;
};

type TPubSubDataDownload = TPubSubDataBase & {
    path: string;
    file_name: string;
};

type TPubSubDataPerk = TPubSubDataBase & {
    company: string;
    perk: string;
};

type TPubSubDataRaffle = TPubSubDataBase & {
    raffle: string;
};

type TPubSubMessageData = TPubSubDataLogin | TPubSubDataChat | TPubSubDataVideoEvent | TPubSubDataPageView
    | TPubSubDataDownload | TPubSubDataPerk | TPubSubDataRaffle;

type TPubSubMessage<T extends EPubSubTable, D extends TPubSubMessageData> = { table: T; data: D };
type TPubSubMessageLogin = TPubSubMessage<EPubSubTable.LogIn, TPubSubDataLogin>;
type TPubSubMessageChat = TPubSubMessage<EPubSubTable.Chat, TPubSubDataChat>;
type TPubSubMessageVideo = TPubSubMessage<EPubSubTable.Video, TPubSubDataVideoEvent>;
type TPubSubMessagePageView = TPubSubMessage<EPubSubTable.PageView, TPubSubDataPageView>;
type TPubSubMessageDownload = TPubSubMessage<EPubSubTable.Download, TPubSubDataDownload>;
type TPubSubMessagePerk = TPubSubMessage<EPubSubTable.Perk, TPubSubDataPerk>;
type TPubSubMessageRaffle = TPubSubMessage<EPubSubTable.Raffle, TPubSubDataRaffle>;

type TPubSubMessages = TPubSubMessageLogin | TPubSubMessageChat | TPubSubMessageVideo | TPubSubMessagePageView
    | TPubSubMessageDownload | TPubSubMessagePerk | TPubSubMessageRaffle;

export class Tracking  {
    public static get Date() {
        return new Date().toISOString().replace("T", " ").split(".")[0];
    }
    public static Analytics(me: Pick<UserEntity, "id" | "old_id" | "email">,  event: string, eventProps: any): void {
        try {
            if (!event) return;

            const user: any = (window as any).user || {};
            const { id, old_id } = me || {};
            const userId: string = ( user.id !== "me" ? user.id : "" ) || old_id || id;
            if (!userId) return;

            const traits: any = {
                email: user.email || me.email,
                name: user.name,
            };

            // console.log("awts analytics ", event, ": ", {
            //     userId,
            //     traits,
            //     event,
            //     eventProps,
            // });

            // remove segment call
            // request.post("/segment", {
            //     userId,
            //     traits,
            //     event,
            //     eventProps,
            // }).then((response: AxiosResponse) => {
            //     // console.error("analytics error: ", {
            //     //     status: response.status,
            //     //     statusText: response.statusText,
            //     // });
            // }).catch((axError) => {
            //     console.error("analytics1 error: ", axError)
            // });
        }
        catch (error) {
            // do nothing
            console.error("analytics2 error: ", error)
        }
    }

    public static PubSub(messages: TPubSubMessages[]): void {
        try {
            if (!messages.length) return;

            // console.log("awts PubSub: ", messages);

            const nonce: number = Date.now();

            // it is important that the properties are sorted alphabetically
            const dataSignature: any = {
                key: app.gcKey,
                messages,
                nonce,
            };

            request.post("/adw-pubsub", {
                conference: "cc202112",
                messages: dataSignature.messages,
                nonce: dataSignature.nonce,
                signature: crypto.createHmac("sha256", app.gcKey!)
                    .update(JSON.stringify(dataSignature))
                    .digest("hex"),
            }).then((response: AxiosResponse) => {
                // console.log("pubsub success: ", response.data);
            }).catch((error) => {
                console.error("pubsub req error: ", error);
            });
        }
        catch (error) {
            console.error("pubsub func error: ", error);
        }
    }
}

import React, { useContext, useEffect, useState } from "react";
import { app } from "config";

import { useOnError } from "hooks";
import { DiContext, useAsync } from "app/common";
import { authService } from "app/infra/auth";

import { Button, Form, Input, notification } from "antd";
import { User } from "react-iconly";
import logoWithLabel from "assets/images/awe/logo/logo-with-label.svg";
import { Link } from "react-router-dom";

export const PasswordlessLogin = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const authSrv = authService({ apiService, dispatch });

  const { execute, pending, value, error } = useAsync<{ ok?: boolean }>((data: { email: string }) => {
    return authSrv.getMagicLink(data.email);
  });
  useOnError(error);

  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (value && value.ok) {
      notification.success({
        message: "Please check your email to login. Make sure to check your spam folder.",
      });

      setSuccessMessage(true);
    }
  }, [value]);

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container">
        <Form
          layout="vertical"
          onFinish={execute}
          className="auth-form"
        >
          <div className="logo">
            <img src={logoWithLabel} alt={`${app.name} Logo`} height="40" />
            <div className="place-date">
              <p>barcelona • 12 - 13 july 2023</p>
            </div>
          </div>

          {successMessage && (
            <div className="ant-row ant-form-item">
              <strong>Your login email is on the way!</strong>
              It can take a moment to arrive, please check your spam folder,
              before contacting support for additional assistance.
            </div>
          )}

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please enter your email!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Enter email"
              prefix={<User set="light" />}
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              block={true}
              loading={pending}
              className="auth-btn"
              style={{ fontWeight: "bold", textTransform: "uppercase", letterSpacing: "1px" }}
            >
              Get magic login link
            </Button>
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Link to="/auth/register" className="didnt-receive-login">
              Having trouble logging in?
            </Link>
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: "center", borderTop: "2px solid", marginBottom: "5px", borderColor: "#4E5255" }}>
              <a
                href={app.landingURL}
                target="_blank"
                rel="noreferrer"
                style={{
                  display: "block",
                  marginTop: "20px",
                  fontWeight: "bold",
                  opacity: 0.7,
                }}
              >
                Don't have a ticket? Get one now!
              </a>
            </div>
          </Form.Item>
        </Form>
        <a href="https://dxb.awconf.online/auth/login" target="__blank">
          <div className="dubai-2023-access-link">
            <span>{">"}</span>
            <p>Affiliate World Dubai 2023</p>
            <span>{"<"}</span>
          </div>
        </a>
      </div>
    </div>
  );
};

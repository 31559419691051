import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { app } from "config";

import { DiContext, useAsync } from "app/common";
import { authService } from "app/infra/auth";

import { notification } from "antd";
import logo from "assets/images/awe/logo/logo-auth.svg";

import { Loading } from "components/Loading/Loading";
import { parse } from "qs";
import { useOnMount } from "hooks";

export const PasswordlessSession = () => {
  const location = useLocation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  const { apiService, dispatch } = useContext(DiContext);
  const authSrv = authService({ apiService, dispatch });

  const { execute, error } = useAsync<{ ok?: boolean }>(() => {
    return authSrv.getJWTFromMagicLink(queryParams.token as string);
  });

  useOnMount(execute);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Your passwordless login link is invalid. Request a new one",
      });
    }
  }, [error]);

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container">
        <div className="logo">
          <img src={logo} alt={`${app.name} Logo`} height="40" />
        </div>

        <div style={{ textAlign: "center", color: "#FFFFFF" }}>
          {error ? (
            <>
              <h3 style={{ color: "#FFCB7D" }}>Your passwordless login link is invalid!</h3>
              <p>
                It might have expired. Request a new one {" "}
                <Link to="/auth/login" style={{ textDecoration: "underline", color: "inherit" }}>
                  here
                </Link>.
              </p>
            </>
          ) : (
            <>
              <h3>Verifying you passwordless login link.</h3>

              <div>
                <Loading size={48} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DragableBodyRow';

export const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: any) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      // @ts-ignore
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const isDraggable = !(
    className.includes('ant-table-expanded-row-level-1') ||
    className.includes('ant-table-placeholder')
  );

  return (
    <tr
      ref={isDraggable ? ref : null}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: isDraggable ? 'move' : 'initial', ...style }}
      {...restProps}
    />
  );
};

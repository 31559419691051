import React, { useContext, Fragment } from "react";
import { ResourceEntity } from "app/infra/resource";
import { DiContext } from "app/common";
import { resourceService } from "app/infra/resource";
import { AgreeShareInformationResource } from "./agreeShareInformationResource.modal";
import { PaperDownload } from "react-iconly";
import { useModal } from "hooks/useModal";

enum FileTypes {
  pdf = "pdf",
  xls = "xls",
  xlsx = "xlsx",
  docx = "docx",
}
interface ResourceProps {
  type: FileTypes;
  title: string;
  size: string;
  link: string;
  id: string;
}

const Resource = ({ type, title, size, link, id }: ResourceProps) => {
  const modal = useModal();
  const { apiService, dispatch } = useContext(DiContext);
  const resourceSrv = resourceService({ apiService, dispatch });

  return (
    <Fragment>
      <a
        download={link}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="resource"
        // onClick={(e) => {
        //   e.preventDefault();
        // }}
      >
        <PaperDownload set="bold" />
        <div
          onClick={() => {
            // modal.open();
            resourceSrv.download(id).then((response) => {
              // modal.shut();
              return response;
            })
          }}
        >
          <h4>{title}</h4>
          <span>{size}</span>
        </div>
      </a>
      <AgreeShareInformationResource
        visible={modal.isVisible}
        onOk={() =>
          resourceSrv.download(id).then((response) => {
            modal.shut();
            return response;
          })
        }
        close={modal.shut}
        url={link}
        title={title}
      />
    </Fragment>
  );
};

interface ResourcesProps {
  resources: ResourceEntity[];
}

export const Resources = ({ resources }: ResourcesProps) => {
  return (
    <div className="resources-container">
      {resources.map((resource, key) => (
        <Resource
          key={key}
          title={resource.file_name}
          size={resource.size}
          type={resource.file_type as FileTypes}
          link={resource.path}
          id={resource.id}
        />
      ))}
    </div>
  );
};

import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { CompanyEntity, TicketType } from "app/infra/company";
import { Avatar } from "antd";
import { Work } from "react-iconly";
import { COMPANY } from "config/constants";

interface CompanyCardProps {
  company: CompanyEntity;
}

const descriptionLimit = 100;

export const Premium = ({ company }: CompanyCardProps) => {
  const description = useMemo(() => {
    return (company.description.length > descriptionLimit
        && `${company.description.substring(0, descriptionLimit)}..`)
        || company.description;
  }, [company]);

  const subtitle = [company.company_type, company.country]
    .filter(Boolean)
    .join(" | ");

  return (
    <div className="company-premium">
      {company.ticket_type === TicketType.CompanyPremiumBooth && (
        <div
          className="img"
          style={
            company.ticket_type === TicketType.CompanyPremiumBooth
              ? {
                backgroundImage: `linear-gradient(#fff -500%, ${company.main_color})`,
                backgroundColor: company.main_color,
              }
              : {}
          }
        >
          <div
            style={{
              position: "absolute",
              bottom: "-60px",
              backgroundColor: "white",
              borderStyle: "solid",
              borderRadius: "50%",
              borderWidth: "25px",
              borderColor: "white",
            }}
          >
            <Link to={`/app/booth/${company.id}`}>
              <Avatar src={company.logo_url} alt={company.name} size={70} style={{ backgroundColor: "#000000" }}>
                <Work set="bold" size={40} primaryColor="#FFFFFF" style={{ verticalAlign: "middle" }} />
              </Avatar>
            </Link>
          </div>
        </div>
      )}

      <div className="header">
        <p className="title">
          <Link to={`/app/booth/${company.id}`}>{company.name}</Link>
        </p>
        <p className="subtitle">{subtitle}</p>
      </div>

      <p className="description">{description}</p>

      <div className="view-btn">
        <Link to={`/app/booth/${company.id}`}>{`View ${COMPANY.meta.name}`}</Link>
      </div>
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { deleteResource, InitialState, updateResources } from "app/common";
import { ReplayTrackEntity } from "./replayTrack.entity";

const initialState = { keyIds: {}, byId: {} } as TrackInitialState;

interface TrackInitialState extends InitialState<ReplayTrackEntity> {}

export const replayTrackStore = createSlice({
  name: "replayTrackStore",
  initialState,
  reducers: {
    reset: () => initialState,
    deleteResource,
    updateResources,
  },
});

import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const WaterDropIcon = (props: BasicProps): ReactElement => {
  return (
    <svg
      className={`adw-water-drop-icon ${props.className || ""}`}
      style={{
        width: props.size,
        height: props.size,
        position: props.position,
        backgroundColor: props.secondaryColor,
        fill: props.primaryColor,
        borderStyle: "solid",
        borderColor: props.secondaryColor,
        borderRadius: props.borderRadius,
        borderWidth: props.borderWidth,
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      viewBox="0 0 18 22"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M9 3.09997L4.05 8.04997C3.07111 9.02897 2.40449 10.2763 2.13445 11.6341C1.86442 12.9919 2.00308
        14.3994 2.53292 15.6784C3.06275 16.9575 3.95996 18.0507 5.11109 18.8198C6.26222 19.5889 7.61557
        19.9995 9 19.9995C10.3844 19.9995 11.7378 19.5889 12.8889 18.8198C14.04 18.0507 14.9373 16.9575
        15.4671 15.6784C15.9969 14.3994 16.1356 12.9919 15.8656 11.6341C15.5955 10.2763 14.9289 9.02897
        13.95 8.04997L9 3.09997ZM9 0.271973L15.364 6.63597C16.6227 7.89465 17.4798 9.4983 17.8271 11.2441C18.1743
        12.9899 17.9961 14.7995 17.3149 16.4441C16.6337 18.0886 15.4802 19.4942 14.0001 20.4831C12.5201 21.4721
        10.78 21.9999 9 21.9999C7.21998 21.9999 5.47992 21.4721 3.99988 20.4831C2.51984 19.4942 1.36629
        18.0886 0.685099 16.4441C0.00390685 14.7995 -0.17433 12.9899 0.172928 11.2441C0.520187 9.4983 1.37734
        7.89465 2.636 6.63597L9 0.271973ZM4 13H14C14 14.3261 13.4732 15.5978 12.5355 16.5355C11.5979 17.4732
        10.3261 18 9 18C7.67392 18 6.40215 17.4732 5.46447 16.5355C4.52679 15.5978 4 14.3261 4 13Z"
      />
    </svg>
  );
};

WaterDropIcon.defaultProps = {
  size: 40,
  weight: "5",
  position: "relative",
  primaryColor: "#E9ECFF",
  secondaryColor: "transparent",
  borderRadius: "50%",
  borderWidth: "10px",
};

export default WaterDropIcon;

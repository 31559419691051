import { createSlice } from "@reduxjs/toolkit";
import { EPubSubTable, jwtDecoder, Tracking} from "app/common/utils";

interface DecodedJWT {
  id: number;
  roles: string[];
}

const initialState = {
  token: null,
  isLoggedIn: false,
  isAdmin: false,
};

export const authStore = createSlice({
  name: "authStore",
  initialState,
  reducers: {
    reset: () => initialState,
    setToken: (_, action) => {
      const jwtInfo = jwtDecoder(action.payload) as DecodedJWT;
      const isAdmin = jwtInfo && jwtInfo?.roles.includes("ROLE_ADMIN");

      Tracking.PubSub([{
        table: EPubSubTable.LogIn,
        data: {
          user_id: jwtInfo.id.toString(),
          datetime: Tracking.Date,
          ip: "",
          user_agent: "",
        },
      }]);

      return {
        token: action.payload,
        isLoggedIn: true,
        // @ts-ignore
        isAdmin,
      };
    },
    logout: () => {
      return { token: null, isLoggedIn: false, isAdmin: false };
    },
  },
});

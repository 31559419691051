import React, { CSSProperties, PropsWithChildren, ReactElement } from "react";

interface Props extends PropsWithChildren<any> {
  className?: string,
  onClick?: () => void,
  style?: CSSProperties,
}

const ReactIconlyWrapper = (props: Props): ReactElement => {
  return (
    <div
      className={`adw-react-iconly ${props.className || ""}`}
      role="none"
      onClick={props.onClick}
      style={{
        cursor: props.onClick ? "pointer" : "default",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

ReactIconlyWrapper.defaultProps = {
  className: "",
  style: "",
  onClick: undefined,
};

export default ReactIconlyWrapper;

import React, { useContext, useEffect, useMemo } from "react";

import { connect, ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync } from "hooks";
import { userFromStoreWithKeyed, userService } from "app/infra/user";

import { Skeleton } from "antd";

import { PersonItem } from "app/presentation/common";

const UserShortList = (props: PropsRedux) => {
  const { apiService, dispatch } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch });

  const { pathname } = useLocation();
  const userIdParam = pathname.split("user-profile/")[pathname.split("user-profile/").length - 1];

  const { execute, isPending } = useAsync(() => userSrv.getAll({
    component: "suggested-users",
    limit: 100,
    page: 0,
    role: "ROLE_ATTENDEE",
    isComplete: true,
  }));

  useEffect(execute, [userIdParam]);

  const users = useMemo(() => {
    return props.users
      .filter((user) => user.id !== props.me.old_id && parseInt(user.id, 10) !== parseInt(userIdParam, 10))
      .slice(0, 3);
  }, [props.users]);

  return (
    <div className="recommended-people-section">
      <div className="person-list">
        {users.length === 0 && isPending ? (
          <>
            <Skeleton avatar={true} active={true} title={true} paragraph={{ rows: 2 }} />
            <Skeleton avatar={true} active={true} title={true} paragraph={{ rows: 2 }} />
            <Skeleton avatar={true} active={true} title={true} paragraph={{ rows: 2 }} />
          </>
        ) : (
          users.map((user) => <PersonItem user={user} key={user.id} me={props.me} />)
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: userFromStoreWithKeyed(state, "suggested-users"),
  me: state.userStore.byId["me"],
});

const connector = connect(mapStateToProps);

type PropsRedux = ConnectedProps<typeof connector>;

export default connector(UserShortList);

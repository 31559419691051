import React, { useEffect, useMemo, useState } from "react";
import { app } from "config";

import { useOnMount } from "hooks/useOnMount";

import moment from "moment-timezone";
import { formatDistance, isAfter } from "date-fns";

import { PostEntity } from "app/infra/posts";

import VimeoPlayer from "@vimeo/player";
import Icons from "components/Icons";
import logo from "assets/images/awg/post-avatar.jpeg";

import { PostReaction } from "./PostReaction";

const PostVideo = (props: { vimeoId: string, isModal: boolean }) => {
  const playerId = useMemo(() => {
    return props.isModal ? `${props.vimeoId}-modal-player` : `${props.vimeoId}-player`;
  }, [props.vimeoId]);

  useEffect(() => {
    try {
      new VimeoPlayer(playerId, {
        url: `https://vimeo.com/${props.vimeoId}`,
        title: false,
        height: 300,
        autoplay: false,
        responsive: true,
      });
    } catch (e) {
      console.error(e);
    }
  }, [props.vimeoId]);

  return <div id={playerId} className="post-video-player" />;
};

export const Post = (props: { post: PostEntity, isModal: boolean }) => {
  const [time, setTime] = useState(Date.now());

  useOnMount(() => {
    const interval = setTimeout(() => {
      setTime(Date.now());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  });

  const distanceOfCreatedAtInWords = useMemo(() => {
    const now = Date.now();
    const releasedAt = moment(props.post.schedule_at || props.post.created_at).toDate();
    return formatDistance(now, releasedAt);
  }, [props.post.schedule_at, time]);

  const isInFuture = useMemo(() => {
    const now = Date.now();
    const releasedAt = moment(props.post.schedule_at || props.post.created_at).toDate();
    return isAfter(releasedAt, now);
  }, [props.post.schedule_at, time]);

  return (
    <div className="social-post" style={{ border: `3px solid ${isInFuture ? "red" : "transparent"}` }}>
      <div className="header">
        <img src={logo} alt={`${app.name} Logo`} className="avatar" />
        <span className="title">{app.name} Team</span>

        {isInFuture && (
          <span style={{ marginLeft: 20 }}>Admin Preview</span>
        )}

        {props.post.pinned && (
          <span style={{ marginLeft: 10 }}>
            <Icons.Pin size={20} primaryColor="#fc7303" />
          </span>
        )}

        <span style={{ marginLeft: "auto", color: "#C4C4C4", paddingBottom: "9px" }} className="time">
          {distanceOfCreatedAtInWords}
        </span>
      </div>

      <div className="content" dangerouslySetInnerHTML={{ __html: props.post.body }} />

      {props.post.vimeo_id && <PostVideo vimeoId={props.post.vimeo_id} isModal={props.isModal} />}

      <PostReaction post={props.post} />
    </div>
  );
};

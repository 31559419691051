import { CommonServiceDeps, PaginationDTO, PaginationEntity } from "app/common";
import { AxiosRequestConfig } from "axios";

import _ from "lodash";

import { companyStore } from "./company.store";
import { UserEntity, userStore } from "../user";
import { CompanyEntity } from "./company.entity";

export interface CreateCompanyDTO {
  name: string;
  description: string;
  ticket_type: string;
  url: string;
  country: string;
  expertise: string[];
  company_type: string[];
}

export interface EditCompanyDTO {
  name: string;
  description: string;
  ticket_type: string;
  url: string | null;
  country: string;
  expertise: string[];
}

export interface GetCompaniesDTO extends PaginationDTO {
  search?: string;
  onlybooths?: boolean;
  component?: string;
  countries?: string[];
  expertise?: string[];
  interest?: string[];
  isComplete?: boolean;
  isLobby?: boolean;
}

export interface UpdateCompanyPhotoDto {
  photo: string | Blob;
}

interface ByIdsDTO {
  ids?: string[];
}

interface ByIdDTO {
  companyId: string;
}

export const companyService = ({
  apiService,
  dispatch,
}: CommonServiceDeps) => ({
  adminCreateCompany: (data: CreateCompanyDTO) =>
    apiService.post<CompanyEntity>("admin/company", data).then((response) => {
      dispatch(companyStore.actions.addCompanies({ companies: [response] }));
      return response;
    }),
  adminUpdateCompany: (data: EditCompanyDTO, id: string) =>
    apiService
      .patch<CompanyEntity>(`admin/company/${id}`, data)
      .then((company) => {
        dispatch(companyStore.actions.addCompanies({ companies: [company] }));
        return company;
      }),
  adminDeleteCompany: (id: string) =>
    apiService.delete(`admin/company/${id}`).then(() => {
      dispatch(companyStore.actions.deleteCompany({ id }));
    }),
  getCompany: (id: string) =>
    apiService.get<CompanyEntity>(`company/${id}`).then((response: CompanyEntity) => {
      dispatch(companyStore.actions.addCompany({ company: response }));
      return response;
    }),
  getCompanyUsers: (id: string) =>
    apiService.get<UserEntity[]>(`company/${id}/users`).then((response) => {
      dispatch(userStore.actions.addOrUpdateUsers({ resources: response }));
      dispatch(
        companyStore.actions.addCompanyUsers({
          id,
          userIds: response.map((user: UserEntity) => user.id),
        })
      );
      return response;
    }),
  updateCompany: (data: EditCompanyDTO, id: string) =>
    apiService.patch<CompanyEntity>(`company`, data).then((response) => {
      dispatch(companyStore.actions.addCompanies({ companies: [response] }));
      return response;
    }),
  updateCompanyPhoto: ({ photo }: UpdateCompanyPhotoDto) => {
    const formData = new FormData();
    formData.append("picture", photo);
    return apiService
      .post("company/logo", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        const company = response as CompanyEntity;
        dispatch(companyStore.actions.addCompanies({ companies: [company] }));
        return response;
      });
  },
  adminUpdateCompanyPhoto: ({ id, photo }: { id: string, photo: string | Blob }) => {
    const formData = new FormData();
    formData.append("picture", photo);
    return apiService
      .post(`admin/company/${id}/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  },
  getCompanies: (data: GetCompaniesDTO) =>
    apiService
      .get("company", {
        params: {
          ...data,
          limit: undefined,
          count: data.limit,
          component: undefined,
        },
      })
      .then<{ data: CompanyEntity<UserEntity>[], pagination: PaginationEntity }>((response) => {
        const companies = response.data as CompanyEntity<UserEntity>[];
        let ids = companies.map((company) => company.id);
        const keyedstamp = `${data.component}-${data.limit}-${data.page}-${data.search}`;
        const users = companies.map((company) => company.users).flat();

        dispatch(userStore.actions.addUsers({ resources: users }));
        dispatch(
          companyStore.actions.addCompanies({ companies: response.data })
        );

        if (data.component) {
          if (data.component === "recommended-companies") {
            ids = _.shuffle(ids);
          }

          dispatch(
            companyStore.actions.addKeyedIds({
              keyedstamp,
              ids,
            }),
          );

          if (data.page === 0) {
            dispatch(
              companyStore.actions.addKeyedIds({
                keyedstamp: data.component,
                ids: [keyedstamp],
              }),
            );
          } else {
            dispatch(
              companyStore.actions.addOrUpdateKeyedIds({
                keyedstamp: data.component,
                ids: [keyedstamp],
              }),
            );
          }
        }

        return response;
      }),

      getBusinessCardLeads: (data: ByIdsDTO, config: AxiosRequestConfig = {}) => {
        return apiService
          .post<string | ArrayBuffer | ArrayBufferView | Blob>("leads/csv", data, config)
          .then((response) => { return response; });
      },

      getBusinessCardStats: (data: ByIdsDTO, config: AxiosRequestConfig = {}) => {
        return apiService
          .post<string | ArrayBuffer | ArrayBufferView | Blob>("companies/leads/stats", data, config)
          .then((response) => { return response; });
      },

      getAll: () => {
        return apiService
          .get("company/all")
          .then((response: CompanyEntity[]) => {
            dispatch(companyStore.actions.addAllCompanies({ companies: response }));
            return response;
          });
      },

      createLead: (data: ByIdDTO) => {
        return apiService
          .post("leads", data);
      },

      getLead: (companyId: string) => {
        return apiService
          .get(`leads/${companyId}`)
          .then((response: boolean) => { return response; });
      },

  users: {
    delete: (companyId: string, id: string) => {
      return apiService
        .delete(`companies/${companyId}/users/${id}`)
        .then((response) => {
          dispatch(companyStore.actions.removeCompanyUsers({ id: companyId, userIds: [id] }));
          dispatch(userStore.actions.deleteUser({ id }));
          return response;
        });
    },
  },
});

import React from "react";
import ReactDOM from "react-dom";

import { App } from "components/App";
import { reportWebVitals } from "services/reportWebVitals";

import "assets/styles/app.less";

if (window?.location?.href?.length > 0) {
  const nakedURL = window.location.href.replace(/^https?:\/\/www\./, "");
  if (nakedURL !== window.location.href) {
    window.location.href = `https://${nakedURL}`;
  }
}

const element = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

ReactDOM.render(element, document.getElementById("root"));

reportWebVitals();

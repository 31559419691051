export const companyExpertises = [
  "Content",
  "Growth",
  "Monetization",
  "Future of Creators",
  "Production",
  "YouTube",
  "TikTok",
  "Instagram",
  "Facebook",
  "Twitter",
  "Snapchat",
  "Patreon",
  "Twitch",
  "Clubhouse",
  "Linkedin",
  "Spotify",
  "Apple Podcasts",
  "Discord",
  "Other",
];

export const attendeeInterests = [
  "Content",
  "Growth",
  "Monetization",
  "Future of Creators",
  "Production",
  "YouTube",
  "TikTok",
  "Instagram",
  "Facebook",
  "Twitter",
  "Snapchat",
  "Patreon",
  "Twitch",
  "Clubhouse",
  "Linkedin",
  "Spotify",
  "Apple Podcasts",
  "Discord",
  "Other",
];
